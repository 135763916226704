
  import { Component, Vue } from "vue-property-decorator";
  import {
    IGenerativeModelCreate,
  } from "@/interfaces";
  import { readGenerativeModel, readGenerativeModels } from "@/store/generative_model/getters";


  
  import ChangeLabelsCard from "@/components/ChangeLabelsCard.vue";
  import { readHasAdminAccess, readToken, readUserProfile } from "@/store/main/getters";
  import { api } from "@/api";
import { dispatchUpdateGenerativeModel } from "@/store/generative_model/actions";
  
  @Component({
    components: {
      ChangeLabelsCard,
    }
  })
  
  export default class CreateLabelContainer extends Vue {
    public id: number = 1;
    public labelContainerName: string = "";
    public modelName: string = "";
    public modelDescription: string = "";
    public dialogLabelContainer: boolean = true;
    public loading: boolean = false;
    public trainWithContext: boolean = false;
    public tab: any = null;
    public modelNameError: string = "";
    public labelContainerNameError: string = "";
    public previousUtterances: number = 0;
    public followingUtterances: number = 0;
    public selectedColor: string | null = null;
    public loadingColor: boolean = false;
  
    public colors: string[] = [
      "purple",
      "pink",
      "deep-purple",
      "red",
      "indigo",
      "blue",
      "light-blue",
      "cyan",
      "teal",
      "green",
      "light-green",
      "lime",
      "yellow",
      "amber",
      "orange",
      "deep-orange",
      "brown",
      "blue-grey",
      "grey",
    ];

    get user() {
      return readUserProfile(this.$store);
    }
  
    get colorOptions() {
      const colors = [
          { name: 'No color', value: null },
          ...this.colors.map(color => ({ name: color, value: color })),
        ];
    
    if (this.user!.is_superuser) {
      colors.push({ name: 'black', value: 'black' });
    }
    return colors;
      
    }
  
    get token() {
      return readToken(this.$store);
    }
  
  
    public async newColor(data) {
      console.log(data);
      this.loadingColor = true;
      await api
        .changeGenerativeColor(
          this.token,
          parseInt(this.$router.currentRoute.params.workspaceid, 10),
          this.model!.id,
          data.value)
        .then((res) => {
          this.loadingColor = false;
          this.selectedColor = data.value;
        })
        .catch((error) => {
          console.log("ERROR", error.response)
          this.loadingColor = false;
        });
    }
  
    get isAdmin() {
      return readHasAdminAccess;
    }
  
    public async mounted() {
     
      this.modelName = this.model ? this.model.name : "";
      this.modelDescription = this.model ? this.model.description : "";
      this.selectedColor = this.model ? this.model.color : null;
    }
  
    get woot() {
      return "woot";
    }
  
    get model() {
      return readGenerativeModel(this.$store)(+this.$router.currentRoute.params.id);
    }
  
    get models() {
      return readGenerativeModels(this.$store);
    }
  
    // ==================MODEL NAME===========
    get modelNameExists(): boolean {
      if (this.modelName === this.model!.name) {
        return false;
      }
      return this.models.filter((model) => model.name === this.modelName).length > 0;
    }
  
    get modelNameErrors(): string[] {
      if (this.modelNameExists) {
        return ["This name does already exist"];
      }
      if (this.modelName.length && (this.modelName.length < 1 || this.modelName.length > 40)) {
        return ["Please provide a name between 1 and 40 characters long"];
      }
      return [];
    }
  
    get modelIsClean(): boolean {
      if (this.modelName.length < 1 || this.modelName.length > 40) {
        return false;
      }
      if (this.modelNameExists) {
        return false;
      }
  
      return true;
    }
    // ==========================================
  
    get labelContainerNameExists(): boolean {
      return false;
    }
  
  
    get labelContainerNameErrors(): string[] {
      if (this.labelContainerNameExists) {
        return ["This name does already exist"];
      }
      return [];
    }
  
    get labelContainerIsClean(): boolean {
      if (this.labelContainerName.length < 1 || this.labelContainerName.length > 80) {
        return false;
      }
      if (this.labelContainerNameExists) {
        return false;
      }
      return true;
    }
  
  
   
  
    public updateModel() {
      this.modelNameError = "";
      const model: IGenerativeModelCreate = {
        name: this.modelName,
        description: this.modelDescription,
      };
      dispatchUpdateGenerativeModel(this.$store, {
        workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
        modelId: this.model!.id,
        model,
      })
        .then((r) => {
          // this.$router.push({
          //   path: `/main/${this.$router.currentRoute.params.workspaceid}
          // /classification/${this.$router.currentRoute.params.id}/dashboard`,
          // });
        })
        .catch((err) => {
          this.modelNameError = err.response;
        });
    }
  }
  