
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { IDataset, IDatasetCreate } from "@/interfaces";
import { readDatasets } from "@/store/dataset/getters";
import {
  dispatchGetDatasets,
  dispatchCreateDataset,
  dispatchDeleteDataset,
} from "@/store/dataset/actions";
import dayjs from "dayjs";

import { readWorkspace } from "@/store/main/getters";
import FileDrop from "@/components/FileDrop.vue";
import DatasetPageCard from "@/components/DatasetPageCard.vue";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

@Component({
  components: {
    FileDrop,
    DatasetPageCard,
  },
})
export default class DatasetView extends Vue {
  public dialog: boolean = false;
  public dialogRemove: boolean = false;
  public loading: boolean = false;
  public removeDatasetId: number = 0;
  public name: string = "";
  public description: string = "";
  public file: any = null;
  public intervalId: number | null = null;

  public async mounted() {
    await this.fetchDatasets();
    this.intervalId = window.setInterval(() => {
      this.fetchDatasets();
    }, 15000);
  }

  public beforeRouteLeave(to: any, from: any, next: any) {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
    }
    next();
  }

  public async fetchDatasets() {
    await dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
  }

  get datasetToRemove() {
    if (this.removeDatasetId > 0) {
      return this.privateDatasets.find((dataset) => dataset.id === this.removeDatasetId);
    } else {
      return { status: "uploading" };
    }
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY HH:mm");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public beforeRouteUpdate(to, from, next) {
    dispatchGetDatasets(this.$store, {
      id: +to.params.workspaceid,
    });
    next();
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get privateDatasets() {
    const pDatasets = this.datasets.filter((dataset) => dataset.public !== true);
    const sortedPDatasets = pDatasets.slice().sort((a, b) => b.id - a.id);
    return sortedPDatasets;
  }

  public removeDataset(id) {
    this.dialogRemove = true;
    this.removeDatasetId = id;
  }

  public async deleteDataset() {
    this.loading = true;
    await dispatchDeleteDataset(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      datasetId: this.removeDatasetId,
    }).then((r) => {
      this.loading = false;
      this.removeDatasetId = 0;
      this.dialogRemove = false;
    });
  }
}
