
import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";

import { readToken } from "@/store/main/getters";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.js";
import colors from "vuetify/es5/util/colors";
import { api } from "@/api";
import {
  readModel,
  readFirstLabelContainer,
  readLabels,
  readAllLabelsByWorkspaceId,
} from "@/store/model/getters";
import { debounce } from "lodash";
import { isEqual } from "lodash";
import TextEditor from "@/components/TextEditor.vue";
import { readDataset, readDatasets } from "@/store/dataset/getters";
import MarkdownIt from "markdown-it";
import ItemPreview from "./ItemPreview.vue";
import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";

@Component({
  components: {
    TextEditor,
  },
})
export default class ConversationBrowser extends Vue {
  @Prop({ default: "" })
  public conversationId: string;
  @Prop({ default: false })
  public showDialog: boolean;
  @Prop({ default: null })
  public start: any;
  @Prop({ default: null })
  public end: any;

  public ws: WaveSurfer | null = null;
  public volume: number = 0.5;
  public currentSegment: any = [];
  public isPlaying: boolean = false;
  public wsRegions: RegionsPlugin | null = null;
  public reRenderKey: number = 0;
  public currentTime: number = 0;
  public lastUpdateTime: number = 0;
  public isLoading: boolean = true;
  public fileLoadingError: boolean = false;
  public conversationError: any = null;
  public datasetId: number = 0;
  public md = new MarkdownIt();

  public entireConversation: any = [];
  public conversationSummary: string = "";
  public conversationSummaryError: any = null;
  public loadingButton: boolean = false;
  public loadingChat: boolean = false;
  public connectedModels: any = [];
  public userChatInput: string = "";
  public chatConversation: any = []; //[{"role": "assistant", "content": "hejsan"}, {"role": "user", "content": "hej på dig med"}];

  public isSticky: boolean = false;
  public divOffsetTop: number = 0; // This will hold the Y-coordinate of the div
  @Ref("stickyDiv") readonly stickyDiv!: HTMLElement;

  public follow: boolean = true;
  public playbackRate: number = 1.0;
  // We decided to hard-code the special column names but we do it via a mapper so that we can easily change it later if we wanna go with dynamic columns in the case of e.g. a zendesk dataset
  public specialColumnMapper = {
    speaker: "speaker",
    filename: "filename",
  };

  public getMarked(text: string) {
    try {
      return this.md.render(text);
    } catch (e) {
      return text;
    }
  }

  public showSimilar(text: string) {
    window.open(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/search?search_string=${text}`,
      "_blank",
    );
  }

  public openPage() {
    console.log("HHE");
    if (this.metadataToShow.hasOwnProperty("tscid")) {
      console.log("dhdhd");
      const tscid = this.metadataToShow["tscid"].replace(/\./g, "");
      window.open(`https://poc.augustus.telia.se/vue/?searchValue=${tscid}`, "_blank");
    }
  }

  public created() {
    this.datasetId = parseInt(this.$router.currentRoute.params.id, 10);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  @Watch("currentSegment")
  public onCurrentSegmentChanged(newSegment: any, oldSegment: any) {
    if (this.follow) {
      if (newSegment.length > 0) {
        // Assuming currentTime is available in your component's data
        const currentTime = this.currentTime;

        // Find the index of the segment within newSegment with the start time closest to currentTime
        const closestSegmentIndex = newSegment.reduce((closestIndex, segment, index) => {
          const closestSegment = newSegment[closestIndex];
          // Check if the current segment's start time is closer to currentTime
          // and is not after currentTime
          if (
            segment.start <= currentTime &&
            (closestSegment.start < segment.start || closestSegment.start > currentTime)
          ) {
            return index; // Return the current index as the new closest
          }
          return closestIndex; // Otherwise, return the previously found closest index
        }, 0);

        // Now find this segment in the entireConversation to get its overall index
        const segmentIndex = this.entireConversation.findIndex(
          (segment) => segment === newSegment[closestSegmentIndex],
        );

        // Use Vue's $refs to access the corresponding Vue component instance
        const segmentComponentRef = this.$refs[`segment-${segmentIndex}`];

        // If the component instance exists, access its root DOM element
        if (segmentComponentRef && segmentComponentRef[0]) {
          const elementToScroll = segmentComponentRef[0].$el as HTMLElement;
          // console.log(elementToScroll); // Check if you have the correct element
          elementToScroll.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
      }
    }
  }

  public filteredLabels(item: any) {
    // Filter out the labels that are not present in unpicked connected models
    return item.user_labels_and_predictions.filter((labelPrediction: any) => {
      // Check if the label_container is present in any unpicked connected model
      const isPresentInUnpickedModel = this.connectedModels.some((model: any) => {
        return !model.picked && model.label_containers[0].id === labelPrediction.label_container;
      });
      // Return true if the label_container is NOT present in any unpicked connected model
      return !isPresentInUnpickedModel;
    });
  }

  public togglePicked(item) {
    item.picked = !item.picked;
  }

  public toggleExpanded(index) {
    this.$set(this.connectedModels[index], "expanded", !this.connectedModels[index].expanded);
  }

  get connectedModelsForDisplay() {
    return this.connectedModels.filter(
      (model) =>
        model.status === "deployed" &&
        model.label_containers.length > 0 &&
        model.label_containers[0].type === "single",
    );
  }
  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.connectedModels = r.data.map((model) => ({
          ...model,
          picked: true, // Default value for 'picked'
          expanded: false, // Default value for 'expanded'
        }));
      })
      .catch((error) => {
        // console.log("error when getting chosen dataset", error);
      });
  }

  get labelRows() {
    const labelOccurrences = {};
    this.entireConversation.forEach((item) => {
      item.user_labels_and_predictions.forEach((label) => {
        // Check if the label_container is not present in any unpicked connected model
        const isPresentInUnpickedModel = this.connectedModels.some((model: any) => {
          return !model.picked && model.label_containers[0].id === label.label_container;
        });

        // Only proceed if the label_container is NOT present in any unpicked connected model
        if (!isPresentInUnpickedModel) {
          const tmpLabel = this.labelById(label.label);
          if (tmpLabel) {
            const labelName = tmpLabel.name;
            if (!labelOccurrences[labelName]) {
              labelOccurrences[labelName] = [];
            }
            labelOccurrences[labelName].push({
              start: item.start,
              end: item.end,
              color: this.labelById(label.label).color,
            });
          } else {
            console.log("the non existent label was:", label.label);
          }
        }
      });
    });
    // console.log("labelOccurrences", labelOccurrences);
    return labelOccurrences;
  }

  public jumpToSegment() {
    this.entireConversation.forEach((seg) => {
      // console.log(seg.start);
      if (seg.start === this.start) {
        this.currentSegment = [seg];
      }
    });
    // console.log("Har kört", this.currentSegment);
  }

  public async mounted() {
    // console.log("Jag har byggt om igen");
    // console.log("mounted", this.conversationId);
    // console.log(this.start);

    this.getConversationSummary();
    window.addEventListener("scroll", this.handleScroll);

    // console.log("hereooooooo");
    this.handleScroll(); // Initialize isSticky on mount
    if (this.showDialog) {
      await this.getConversation();
      if (this.start !== null) {
        this.jumpToSegment();
      }

      await this.startWs();
      await this.getDatasetConnectedModels();
    }
    console.log("Metadata to show", this.metadataToShow);
  }

  public getDivOffsetTop(): number {
    if (this.stickyDiv) {
      const rect = this.stickyDiv.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop;
    }
    return 0;
  }

  public handleScroll() {
    const scrollTop = window.scrollY;
    this.isSticky = scrollTop > this.divOffsetTop;
  }

  public formatTime(timeInSeconds: number) {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  public shouldBeOnRight(item: any) {
    if (item[this.specialColumnMapper.speaker] === "agent") {
      return true;
    } else {
      return false;
    }
  }

  public getSpeakerColor(item: any) {
    if (item[this.specialColumnMapper.speaker] === "agent") {
      return "#90caf9";
    } else if (item[this.specialColumnMapper.speaker] === "caller") {
      return "#80CBC4";
    } else {
      return "#9b58ed";
    }
  }

  public getSpeakerIcon(item: any) {
    if (item[this.specialColumnMapper.speaker] === "agent") {
      return "support_agent";
    } else if (item[this.specialColumnMapper.speaker] === "caller") {
      return "face";
    } else {
      return "smart_toy";
    }
  }

  public shouldShowPlaySection(item: any) {
    if (
      item[this.specialColumnMapper.speaker] === "agent" ||
      item[this.specialColumnMapper.speaker] === "caller"
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async getFile() {
    if (this.entireConversation.length > 0) {
      // all rows will contain the filename, so we take the first
      console.log(
        "returning filename",
        this.entireConversation[0][this.specialColumnMapper.filename],
      );
      return this.entireConversation[0][this.specialColumnMapper.filename];
    } else {
      return "";
    }
  }

  public labelById(labelId) {
    if (labelId === -2) {
      return { name: "skipped", color: "grey" };
    }
    return this.labels.filter((label) => label.id === labelId)[0];
  }

  get metadataToShow() {
    const excludeKeys = [
      "plain_text",
      "start",
      "end",
      "embeddings",
      "time_inserted_into_es",
      "clusters",
      "user_labels",
      "keywords",
      "predictions",
      "_id",
      "user_labels_and_predictions",
      "frontstore",
      "embedded",
      "speaker",
      "origin",
      "confidence",
      "min_confidence",
    ];
    const firstMessage = { ...this.entireConversation[0] };

    const filteredObject = Object.keys(firstMessage).reduce((acc, key) => {
      if (!excludeKeys.includes(key)) {
        acc[key] = firstMessage[key];
      }
      return acc;
    }, {});

    console.log(filteredObject);
    return filteredObject;
  }

  get labels() {
    return readAllLabelsByWorkspaceId(this.$store)(+this.$router.currentRoute.params.workspaceid);
  }

  // Player related methods

  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    try {
      if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      } else {
        return `rgb(${r}, ${g}, ${b})`;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );
  }

  public async getAudioFile(): Promise<Blob | null> {
    try {
      const filename = await this.getFile();
      const response = await api.getAudioFile(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        filename,
      );
      const audioBlob = new Blob([response.data], { type: "audio/wav" });
      this.loadingButton = false;
      return audioBlob;
    } catch (error) {
      console.log("error when getting file", error);
      this.loadingButton = false;
      return null;
    }
  }

  // WS things
  public async startWs() {
    this.fileLoadingError = false;
    this.ws = WaveSurfer.create({
      container: this.$refs.waveform as HTMLElement,
      waveColor: "#efedf5",
      progressColor: "orange",
      barWidth: 5,
    });

    this.wsRegions = this.ws.registerPlugin(RegionsPlugin.create());
    // console.log("getting audio file");
    try {
      const audioBlob: Blob | null = await this.getAudioFile();
      if (audioBlob) {
        const blobUrl = URL.createObjectURL(audioBlob);
        this.ws.load(blobUrl);
      } else {
        console.error("Failed to get audio file");
        this.isLoading = false;
        this.fileLoadingError = true;
      }
    } catch (error) {
      this.isLoading = false;
      this.fileLoadingError = true;
      console.error("Error loading audio into WaveSurfer:", error);
    }

    this.ws.on("audioprocess", () => {
      try {
        if (this.ws) {
          const currentTime = this.ws.getCurrentTime();
          const tempSegment = this.entireConversation.filter(
            (segment) => segment.start <= currentTime && segment.end >= currentTime,
          );
          const isEqualToCurrentSegment = isEqual(tempSegment, this.currentSegment);

          if (!isEqualToCurrentSegment) {
            this.currentSegment = tempSegment;
          }
        }
      } catch (error) {
        console.error("Error in audioprocess event listener:", error);
      }
    });

    this.ws.on("ready", () => {
      this.isLoading = false;
    });

    this.ws.on("play", () => {
      this.isPlaying = true;
    });

    this.ws.on("pause", () => {
      this.isPlaying = false;
    });

    this.ws.on("finish", () => {
      this.isPlaying = false;
    });

    this.ws.on("ready", () => {
      this.createRegions();
    });

    this.ws.on("audioprocess", (time) => {
      const roundedTime = Math.round(time);
      if (roundedTime !== this.lastUpdateTime) {
        this.currentTime = roundedTime;
        this.lastUpdateTime = roundedTime;
      }
    });
  }

  public async createRegions() {
    if (this.ws && this.wsRegions) {
      this.entireConversation.forEach((conversation) => {
        // Add a black marker at the end of the region
        if (this.wsRegions) {
          this.wsRegions.addRegion({
            start: conversation.start,
            color: "black",
          });
        }
      });
    }
  }
  public async beforeRouteUpdate(to, from, next) {
    console.log("DESTROYING");
    if (this.ws) {
      this.ws.destroy();
    }
    next();
  }

  public alterPlaybackSpeed(speed: number) {
    console.log("setting playback rate to", speed);
    if (this.ws) {
      this.ws.setPlaybackRate(speed);
    }
  }

  public beforeDestroy() {
    // Your cleanup code here
    if (this.ws) {
      this.ws.destroy();
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  @Watch("isPlaying")
  public onIsPlayingChanged(newIsPlaying: boolean, oldIsPlaying: boolean) {
    console.log(`Playback status changed from ${oldIsPlaying} to ${newIsPlaying}`);
  }

  public skipForward() {
    if (this.ws) {
      const currentTime = this.ws.getCurrentTime();
      const nextSegment = this.entireConversation.find((segment) => segment.start > currentTime);
      if (nextSegment) {
        this.ws.seekTo(nextSegment.start / this.ws.getDuration());
        this.currentSegment = [nextSegment];
        const startPercent = nextSegment.start / this.ws.getDuration();

        // Seek to the start of the section and start playing
        this.ws.seekTo(startPercent);
      }
    }
  }
  public skipBackward() {
    if (this.ws) {
      const currentTime = this.ws.getCurrentTime();
      const previousSegments = this.entireConversation.filter(
        (segment) => segment.end < currentTime,
      );

      // Sort the previous segments by start time in descending order
      // so the segment with the closest start time is first
      previousSegments.sort((a, b) => b.start - a.start);

      // Get the segment with the closest start time
      const closestPreviousSegment = previousSegments[0];

      // Find the current active segments
      const currentSegments = this.entireConversation.filter(
        (segment) => segment.start <= currentTime && segment.end >= currentTime,
      );

      // If there are active segments and we're more than 2 seconds into the first one, seek to its start
      if (currentSegments.length > 0 && currentTime - currentSegments[0].start > 2) {
        this.ws.seekTo(currentSegments[0].start / this.ws.getDuration());
      } else if (closestPreviousSegment) {
        // If less than 2 seconds into the first active segment or no active segments, seek to the closest previous segment
        this.ws.seekTo(closestPreviousSegment.start / this.ws.getDuration());
        // Update the currentSegments to include only the closest previous segment
        this.currentSegment = [closestPreviousSegment];
      }
    }
  }

  public play() {
    if (this.ws) {
      this.ws.play();
    }
  }

  public pause() {
    if (this.ws) {
      this.ws.pause();
    }
  }

  @Watch("volume")
  public onVolumeChanged(newVolume: number) {
    if (this.ws) {
      this.ws.setVolume(newVolume);
    }
  }

  public resetAudio() {
    if (this.ws) {
      this.ws.stop();
      this.ws.seekTo(0);
      this.currentSegment = [this.entireConversation[0]];
    }
  }

  private onAudioProcess: (() => void) | null = null;

  public playSection(start: number, end: number) {
    if (this.ws) {
      // If there's an existing audioprocess event listener, remove it
      if (this.onAudioProcess) {
        this.ws.un("audioprocess", this.onAudioProcess);
      }

      // Convert the start and end times to percentages of the total duration
      const startPercent = start / this.ws.getDuration();
      const endPercent = end / this.ws.getDuration();

      // Define the new audioprocess event listener
      this.onAudioProcess = () => {
        if (this.ws && this.ws.getCurrentTime() >= end) {
          this.ws.pause();
          this.ws.seekTo(startPercent);
          // Remove the event listener to avoid stopping playback at this time in the future
          if (this.onAudioProcess) {
            this.ws.un("audioprocess", this.onAudioProcess);
          }
        }
      };

      // Add the new audioprocess event listener
      this.ws.on("audioprocess", this.onAudioProcess);

      // Seek to the start of the section and start playing
      this.ws.seekTo(startPercent);
      this.ws.play();
    }
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public async getConversation() {
    this.loadingButton = true;
    this.conversationError = null;
    console.log("getting conversation");
    await api
      .getConversation(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.conversationId,
      )
      .then((r) => {
        console.log("RESPONSE", r.data);
        this.entireConversation = r.data;
        if (!this.isAdmin(this.$store)) {
          this.entireConversation = this.entireConversation.filter(
            (item) => item.origin !== "conversation_item",
          );
        }
        this.loadingButton = false;
      })
      .catch((error) => {
        console.log("error when getting conversation", error);
        this.conversationError = error.response;
        this.loadingButton = false;
      });
  }

  public getConversationSummary(generateNew: boolean = false) {
    if (generateNew) {
      // if we generate a new summary, we need to clear the old one
      this.conversationSummary = "";
      this.chatConversation = [];
    }
    console.log("dataset", this.dataset);
    this.loadingChat = true;
    api
      .streamConversationResponse(
        this.token,
        this.dataset === undefined ? null : this.dataset.summary_model,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.conversationId,
        this.chatConversation,
        generateNew,
      )
      .then((reader) => {
        let decoder = new TextDecoder();

        const processText = (result: ReadableStreamReadResult<Uint8Array>) => {
          if (result.done) {
            console.log("Stream complete");
            this.chatConversation.push({
              role: "assistant",
              content: `\n In addition to the given conversation, here is a summary of it: ${this.conversationSummary}`,
            });
            // send request to save the summary
            api.saveConversationSummary(
              this.token,
              parseInt(this.$router.currentRoute.params.workspaceid, 10),
              parseInt(this.$router.currentRoute.params.id, 10),
              this.conversationId,
              this.conversationSummary,
            );
            return;
          }

          this.conversationSummary += decoder.decode(result.value); // append response string chunk

          // Read the next chunk
          reader
            .read()
            .then(processText)
            .catch((error) => {
              console.log("error when getting conversation summary", error);
              this.conversationSummaryError = error;
            });
        };

        // Start reading the stream
        reader
          .read()
          .then(processText)
          .catch((error) => {
            console.log("error when getting conversation summary", error);
            this.conversationSummaryError = error;
          });
        this.loadingChat = false;
      })
      .catch((error) => {
        console.log("error when getting conversation summary", error);
        this.conversationSummaryError = error;
        this.loadingChat = false;
      });
  }
  public getUpdatedChat() {
    console.log("CHATTER", this.chatConversation);
    this.loadingChat = true;

    // add the user input to the chat and clear the input
    this.chatConversation.push({ role: "user", content: this.userChatInput });
    this.userChatInput = "";

    api
      .streamConversationResponse(
        this.token,
        this.dataset === undefined ? null : this.dataset.summary_model,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.conversationId,
        this.chatConversation,
      )
      .then((reader) => {
        let decoder = new TextDecoder();
        // create a new assistant response
        this.chatConversation.push({ role: "assistant", content: "" });
        let lastIndex = this.chatConversation.length - 1;
        const processText = (result: ReadableStreamReadResult<Uint8Array>) => {
          if (result.done) {
            console.log("Stream complete for chat");
            return;
          }

          console.log("RESPONSE CHAT", decoder.decode(result.value));
          this.chatConversation[lastIndex].content += decoder.decode(result.value); // append response string chunk

          // Read the next chunk
          reader
            .read()
            .then(processText)
            .catch((error) => {
              console.log("error when getting chat", error);
              this.chatConversation.push({
                role: "assistant",
                content: "Something went wrong. Please try again later.",
              });
              this.loadingChat = false;
            });
        };

        // Start reading the stream
        reader
          .read()
          .then(processText)
          .catch((error) => {
            console.log("error when getting chat", error);
            this.chatConversation.push({
              role: "assistant",
              content: "Something went wrong. Please try again later.",
            });
            this.loadingChat = false;
          });
        this.loadingChat = false;
      })
      .catch((error) => {
        console.log("error when getting chat", error);
        this.chatConversation.push({
          role: "assistant",
          content: "Something went wrong. Please try again later.",
        });
        this.loadingChat = false;
      });
  }

  get token() {
    return readToken(this.$store);
  }

  public setNewText(newText: string, itemId: string) {
    const index = this.entireConversation.findIndex((item) => item._id === itemId);
    this.entireConversation[index].plain_text = newText;
  }
}
