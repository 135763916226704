
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

@Component
export default class InternalDatasetCard extends Vue {
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ default: null })
  public model!: any;
  public progress: boolean = false;

  public async mounted() {}

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY HH:mm");
  }

  get statusCheck() {
    // returns true if status indicates training/uploading etc
    if (this.model?.status === "complete" || this.model?.status === "ready") {
      if (this.model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }
}
