
import { IGenerativeModelPromptUpdate } from "@/interfaces";
import { dispatchGetGenerativeConnectedDatasets } from "@/store/generative_model/actions";
import { readGenerativeModel, readConnectedDatasets } from "@/store/generative_model/getters";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api";

import { readHasAdminAccess, readToken, readUserProfile } from "@/store/main/getters";

import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import ItemPreviewMultiLabel from "@/components/ItemPreviewMultiLabel.vue";
import ItemPreviewContext from "@/components/ItemPreviewContext.vue";
import LabelBar from "@/components/LabelBar.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import Particle from "@/components/Particle.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import TextEditor from "@/components/TextEditor.vue";
import { readDatasets } from "@/store/dataset/getters";
import MarkdownIt from 'markdown-it';

@Component({
  components: {
    ItemPreviewMultiLabel,
    ItemPreview,
    ItemPreviewContext,
    ModelProgress,
    LabelBar,
    Particle,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
    TextEditor,
  },
})
export default class PromptTuningView extends Vue {
  public responses: any[] = Array(5).fill("");
  public items: any[] = [];

  public userPromptInput: string = "";
  public systemPromptInput: string = "";
  public savePromptAlert: boolean = false;
  public savePromptError: boolean = false;
  public idInput: string = '';
  public loadingAdd: boolean = false;
  public md = new MarkdownIt();
  

  public getMarked(text: string) {
    try {
      return this.md.render(text);
    } catch (e) {
      return text;
    }
  }

  public async addIdsToItems() {
    this.loadingAdd = true;
  // Split the input by commas, trim each item, and filter out empty strings
  const newItems = this.idInput
    .split(',')
    .map(item => item.trim())
    .filter(item => item !== '');

  // Only add to items if getConversation is successful and returns data
  for (const item of newItems) {
    const isSuccess = await this.getConversation(item);
    if (!isSuccess) {
      console.log(`Skipping item ${item} due to empty or failed response.`);
    }
  }

  // Clear the input field
  this.idInput = '';
  this.loadingAdd = false;
}

public async getConversation(conversationId: string): Promise<boolean> {
  console.log("getting conversation");
  try {
    const response = await api.getConversation(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
      this.connectedDatasets[0].id,
      conversationId,
    );
    
    // Check if response is not empty, add to items if valid
    if (response && response.data && response.data.length > 0) {
      console.log("RESPONSE", response.data);
      this.items.push(response.data);
      return true; // Indicate success
    } else {
      console.log(`No data for conversationId ${conversationId}`);
      return false; // Indicate empty or invalid response
    }
  } catch (error) {
    console.log("Error when getting conversation", error);
    return false; // Indicate failure
  }
}

  public async mounted() {
    await dispatchGetGenerativeConnectedDatasets(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    });

    this.userPromptInput = this.userPrompt;
    this.systemPromptInput = this.systemPrompt;
  }

  public async getRandomConversations() {
    console.log("items.length", this.items.length);
    const modelId = parseInt(this.$router.currentRoute.params.id, 10);
    const response = await api.getRandomConversations(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),

      modelId,
    );
    this.items = response.data;
    console.log("items", this.items);
    this.sendRequests();
  }

  public sendToConversation(conversationId) {
    // open a new window routing to the conversation view
    const datasetId = this.connectedDatasets[0].id;
    window.open(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/browse?conversation_id=${conversationId}`,
      "_blank",
    );
  }

  public async sendRequests() {
    // clear the responses
    this.responses = Array(this.items.length).fill("");
    const promises = this.items.map((_, i) => this.sendRequest(i));
    await Promise.all(promises);
  }

  get conversationColumn() {
    if (
      this.connectedDatasets[0] &&
      this.connectedDatasets[0].meta_data &&
      this.connectedDatasets[0].meta_data.columns
    ) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.connectedDatasets[0].meta_data.columns)
        ? this.connectedDatasets[0].meta_data.columns
        : Object.values(this.connectedDatasets[0].meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );
      return conversationColumn.name;
    }
    return "";
  }

  get prompt() {
    return this.userPromptInput;
  }

  get system_prompt() {
    return this.systemPromptInput;
  }

  get systemPrompt() {
    if ("system_prompt" in this.model?.prompts) {
      return this.model?.prompts.system_prompt;
    } else {
      return "";
    }
  }

  get userPrompt() {
    if ("user_prompt" in this.model?.prompts) {
      return this.model?.prompts.user_prompt;
    } else {
      return "";
    }
  }

  public async savePrompt() {
    const workspaceId = parseInt(this.$router.currentRoute.params.workspaceid, 10);
    const promptUpdate: IGenerativeModelPromptUpdate = {
      prompts: {
        system_prompt: this.systemPromptInput,
        user_prompt: this.userPromptInput,
      },
    };
    const modelId = parseInt(this.$router.currentRoute.params.id, 10);
    api.saveGenerativePrompt(this.token, modelId, workspaceId, promptUpdate).then(() => {
      
      this.savePromptError = false;
    }).catch(() => {
      this.savePromptError = true;
    });

    this.savePromptAlert = true;
  }

  public async sendRequest(index: number) {
    // const response = await api.getRecommendation(this.token, this.model.id);

    let fullPrompt = this.prompt + "\n";
    for (let i = 0; i < this.items[index].length; i++) {
      if (this.items[index][i].speaker === "agent" || this.items[index][i].speaker === "caller") {
        fullPrompt += `${this.items[index][i].speaker}: ${this.items[index][i].plain_text}\n`;
      }
    }

    const messages = [
      { role: "system", content: this.system_prompt },
      { role: "user", content: fullPrompt },
    ];

    api
      .callStreamingGenerativeModel(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        messages,
        this.model?.id, // Handling case when a prompt model is trained will be done in the backend, we simply send id to the backend
      )
      .then((reader) => {
        let decoder = new TextDecoder();

        const processText = (result: ReadableStreamReadResult<Uint8Array>) => {
          if (result.done) {
            return;
          }

          this.responses.splice(index, 1, this.responses[index] + decoder.decode(result.value)); // append response string chunk

          // Read the next chunk
          reader
            .read()
            .then(processText)
            .catch((error) => {
              console.log("error when getting conversation summary", error);
            });
        };

        // Start reading the stream
        reader
          .read()
          .then(processText)
          .catch((error) => {
            console.log("error when getting conversation summary", error);
          });
        // this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting conversation summary", error);
        // this.inferenceError = error;
        // this.loading = false;
      });
  }

  get connectedDatasets() {
    return readConnectedDatasets(this.$store);
  }
  get model() {
    return readGenerativeModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get token() {
    return readToken(this.$store);
  }
}
