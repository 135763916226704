
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import {
  ILabel,
  IMetrics,
  IModel,
  ILabelCreate,
  ILabelContainerCreate,
  ILabelUpdate,
  IUserLabels,
  IItemUpdate,
  ITargetLabel,
  IRecommendationUpdate,
} from "@/interfaces";
import {
  readMetrics,
  readModels,
  readPreviewItems,
  readItems,
  readItem,
  readLabels,
  readLabel,
  readModel,
  readPredictions,
  readPrediction,
  readAccuracy,
  readRecommendation,
  readRecommendationType,
  readTarget,
  readFirstLabelContainer,
  readLabelColorsByIds,
  readLabelsByIds,
  readLabelsByName,
  readTotalPredictions,
  readLabelCountsByIds,
  readPredictionsByIds,
} from "@/store/model/getters";
import {
  dispatchGetModels,
  dispatchCreateLabelContainer,
  dispatchCreateLabel,
  dispatchGetItem,
  dispatchGetLastItem,
  dispatchAddItemLabels,
  dispatchGetPredictions,
  dispatchGetAccuracy,
  dispatcGetPredictionItem,
  dispatchGetOriginal,
  dispatchGetRecommendation,
  dispatchGetValidationRecommendation,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";

import { readDatasets, readDataset } from "@/store/dataset/getters";

import {
  readToken,
  readUserProfile,
  readHasAdminAccess,
  readWorkspace,
  readActiveWorkspace,
} from "@/store/main/getters";
import { api } from "@/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

import { ISubscription } from "@/interfaces";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ModelCard from "@/components/ModelCard.vue";

import CSATBarChart from "@/components/CSATBarChart.vue";

@Component({
  components: {
    ItemPreview,
    CSATBarChart,
    ModelProgress,
    LabelBar,
    LabelChart,
    ToolbarButton,
    RoundProgress,
    ModelCard,
  },
})
export default class GraphsView extends Vue {
  public loading: boolean = false;
  public connectedModels: any = [];
  public subscribedToPlan: ISubscription = {} as ISubscription;
  public pagination: any = { rowsPerPage: 3 };

  // free inferences left
  public async getSubscription() {
    await api
      .getSubscription(this.token, parseInt(this.$router.currentRoute.params.workspaceid, 10))
      .then((r) => {
        this.subscribedToPlan = r.data;
      })
      .catch((err) => {
        console.log("error", err);
      });
  }

  // functions for table
  public parsedDate(timestamp) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(timestamp).tz(timeZone).format("MMMM D, YYYY h:mm A");
  }

  public getModel(modelId) {
    return readModel(this.$store)(modelId);
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  public isCurrentCheckpoint(modelId, checkpointName) {
    const model = this.getModel(modelId);
    const labelContainer = model!.label_containers[0];
    if (labelContainer!.checkpoint) {
      const checkpoint = labelContainer!.checkpoint;
      if (checkpoint === checkpointName) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // ==================
  get userProfile() {
    return readUserProfile(this.$store);
  }
  public async mounted() {
    const workspace = readActiveWorkspace(this.$store);
    if (
      this.userProfile?.workspaces.some(
        (ws) => ws.workspace_id === workspace && ws.role === "labeler",
      )
    ) {
      this.$router.push(
        "/main/" + this.$router.currentRoute.params.workspaceid + "/classification/view",
      );
    }

    await this.getDatasetConnectedModelsWithLinks();
  }

  public hasProperty(specialColumnValue: string) {
    let hasValue = false;

    for (const key in this.dataset!.meta_data.columns) {
      if (this.dataset!.meta_data.columns.hasOwnProperty(key)) {
        const column = this.dataset!.meta_data.columns[key];
        if (column.special_column === specialColumnValue) {
          hasValue = true;
          break;
        }
      }
    }
    return hasValue;
  }

  public launchGraphCheck(modelId: number) {
    // let connection = this.connectedModels.filter((connection) => connection.model_id === modelId)[0];
    // console.log(connection)
    if (this.hasProperty("date") && this.hasProperty("csat")) {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${modelId}/csat`,
      );
    } else if (
      this.hasProperty("date") &&
      this.hasProperty("response_time") &&
      this.hasProperty("resolve_time")
    ) {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${modelId}/time`,
      );
    } else {
      this.$router.push(
        `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${this.$router.currentRoute.params.id}/dashboard/graphs/model/${modelId}/setup?setup_type=csat&redirect=graphs`,
      );
      console.log("none");
    }
  }

  public async getDatasetConnectedModelsWithLinks() {
    let allConnectedModels: any = [];
    await api
      .getDatasetConnectedModelsWithLinks(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        allConnectedModels = r.data;
      })
      .catch((error) => {
        console.log("error when getting chosen dataset", error);
      });
    this.connectedModels = allConnectedModels.filter(
      (model) => model.model.label_containers[0].type === "single",
    );
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  get token() {
    return readToken(this.$store);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }
}
