
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { api } from "@/api";
import { readToken, readWorkspace } from "@/store/main/getters";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import {
  readLabels,
  readLabelsByIds,
  readLabelsByName,
  readModel,
  readModels,
} from "@/store/model/getters";

import { ILabel, IModel, IRecommendationUpdate, ITargetLabel, IItemUpdate } from "@/interfaces";

import { dispatchAddItemLabels } from "@/store/model/actions";
import MarkdownIt from "markdown-it";

@Component
export default class ModelCard extends Vue {
  @Prop({ default: null })
  public modelId!: number;
  @Prop({ default: null })
  public childModelId!: number;

  public rows: any[] = [];
  public loading: boolean = false;
  public rowsToShow: any[] = [];
  public exportError: string = "";
  public prepareExportError: string = "";
  public internalDatasetError: string = "";
  public downloadProgress: number = 0;
  public numberHits: number = 0;
  public page: number = 1;
  public pageSize: number = 10;
  public filterId: any = null;
  public updateReason: any = null;
  public md = new MarkdownIt();

  public searchTerm: string = "";

  public headers = [
    {
      text: "Text",
      sortable: true,
      value: "plain_text",
      align: "center",
    },
    {
      text: "Label",
      sortable: true,
      value: "label_id",
      align: "center",
    },
    {
      text: "",
      sortable: true,
      value: "id",
      align: "center",
    },
  ];

  get validTds() {
    if (this.childModelId !== null) {
      return ["plain_text", "user_labels", "child_user_labels", "id"];
    } else {
      return ["plain_text", "user_labels", "id"];
    }
  }

  public getMarked(text: string) {
    try {
      return this.md.render(text);
    } catch (e) {
      return text;
    }
  }

  public openPage(item: any) {
    console.log(item);
    api
      .getConversationIdFromItemId(
        this.token,
        parseInt(this.$router.currentRoute.params.id, 10),
        item!.store_id,
        item!.id,
      )
      .then((response) => {
        console.log(response.data.conversation_id);
        window.open(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${response.data.dataset_id}/dashboard/browse?conversation_id=${response.data.conversation_id}`,
          "_blank",
        );
      });
  }

  get numPages() {
    return Math.ceil(this.numberHits / this.pageSize);
  }

  public addLabelFilter(item) {
    this.filterId = item;
    this.page = 1;
    this.getExamples();
  }

  public async changeLabel(changeToLabelId: number, item: any) {
    this.loading = true;
    console.log("change label", changeToLabelId, item);
    console.log("using model id", this.modelId);

    //this is recommendation recommendation_type='single' recommendation='correction' target=Target(label_container=115, label=1727) items=[UpdateUserLabel(id='852f780907bb41c', store_id='1_76fa53b430a245448ef469415688823d', user_labels=[UserLabel(label_container=115, label=1726)])]

    const label: ITargetLabel = {
      label_container: this.model!.label_containers[0]!.id,
      label: changeToLabelId,
    };

    const updateItem: IItemUpdate = {
      id: item.id,
      store_id: item.store_id,
      user_labels: [label],
    };

    let recommendation = "correction";

    if (item.user_labels[0].label === -1) {
      recommendation = "random";
    }

    const update: IRecommendationUpdate = {
      recommendation_type: "single",
      recommendation: recommendation,
      target: label,
      items: [updateItem],
    };

    await dispatchAddItemLabels(this.$store, {
      modelId: this.modelId,
      labels: update,
    }).then(
      (r) => {
        this.loading = false;
        this.getExamples();
      },
      (reason) => {
        console.log(reason);
        this.updateReason = "Something went wrong when updating the label";
        this.loading = false;
      },
    );
  }

  get sortedLabelsParent() {
    if (this.labelsAndSkippedParent) {
      let sorted = this.labelsAndSkippedParent!.map((o) => ({ ...o }));
      sorted = sorted.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
      if (sorted.length === 0) {
        sorted = this.labelsAndSkippedParent!.map((o) => ({ ...o }));
        sorted = this.searchCategories(this.searchTerm, sorted);
      }
      return sorted;
    }
    return this.labelsAndSkippedParent;
  }

  get sortedLabels() {
    if (this.labelsAndSkipped) {
      let sorted = this.labelsAndSkipped!.map((o) => ({ ...o }));
      sorted = sorted.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
      if (sorted.length === 0) {
        sorted = this.labelsAndSkipped!.map((o) => ({ ...o }));
        sorted = this.searchCategories(this.searchTerm, sorted);
      }
      return sorted;
    }
    return this.labelsAndSkipped;
  }

  // Levenshtein distance function
  public levenshtein(a: string, b: string): number {
    const matrix = Array.from({ length: a.length + 1 }, () => new Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) {
      matrix[i][0] = i;
    }
    for (let j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost,
        );
      }
    }

    return matrix[a.length][b.length];
  }

  // N-gram public
  public ngrams(s: string, n: number): Set<string> {
    const result = new Set<string>();

    for (let i = 0; i <= s.length - n; i++) {
      result.add(s.slice(i, i + n));
    }

    return result;
  }

  // Main search public
  public searchCategories(query: string, categories: ILabel[]): ILabel[] {
    const ngramSize = 3;
    const queryNgrams = this.ngrams(query.toLowerCase(), ngramSize);

    const scoredCategories = categories.map((category) => {
      const categoryNgrams = this.ngrams(category.name.toLowerCase(), ngramSize);
      const intersectionSize = [...queryNgrams].filter((x) => categoryNgrams.has(x)).length;
      const jaccardIndex =
        intersectionSize / (queryNgrams.size + categoryNgrams.size - intersectionSize);
      const levDist = this.levenshtein(query.toLowerCase(), category.name.toLowerCase());
      const score = jaccardIndex * (1 / (1 + levDist));
      return { ...category, score };
    });

    return scoredCategories.sort((a, b) => b.score - a.score);
  }

  public labelObject(labelObject: any) {
    const labelId = labelObject.label;
    let filteredLabel: any = [];

    filteredLabel = this.labels!.filter((labelItem) => labelItem.id === labelId);

    if (filteredLabel.length === 0) {
      if (labelId === -2) {
        return {
          name: "skipped",
          color: "#000000",
        };
      } else if (labelId === -1) {
        return {
          name: "unlabeled",
          color: "#E8E6E5",
        };
      } else if (labelId === 0) {
        return {
          name: "unlabeled",
          color: "#E8E6E5",
        };
      } else {
        return {
          name: "UNKNOWN LABEL",
          color: "#000000",
        };
      }
    }
    return {
      name: filteredLabel[0].name,
      color: filteredLabel[0].color,
    };
  }

  public labelObjectChild(labelObject: any) {
    const labelId = labelObject.label;
    let filteredLabel: any = [];

    let tmpModel = this.models!.filter((model) => model.id === this.childModelId);
    filteredLabel = tmpModel[0].label_containers[0].labels.filter(
      (labelItem) => labelItem.id === labelId,
    );

    if (filteredLabel.length === 0) {
      if (labelId === -2) {
        return {
          name: "skipped",
          color: "#000000",
        };
      } else if (labelId === -1) {
        return {
          name: "unlabeled",
          color: "#E8E6E5",
        };
      } else if (labelId === 0) {
        return {
          name: "unlabeled",
          color: "#E8E6E5",
        };
      } else {
        return {
          name: "UNKNOWN LABEL",
          color: "#000000",
        };
      }
    }
    return {
      name: filteredLabel[0].name,
      color: filteredLabel[0].color,
    };
  }

  get models() {
    return readModels(this.$store);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get token() {
    return readToken(this.$store);
  }

  get labelsAndSkippedParent() {
    let labelsCopy = readLabels(this.$store)(+this.$router.currentRoute.params.id);

    const skipped: ILabel = {
      counter: 0,
      description: "Skip this example",
      id: -2,
      name: "skip",
      color: "#000000",
    };
    labelsCopy.push(skipped);
    return labelsCopy;
  }

  get labelsAndSkipped() {
    let labelsCopy: any = [];
    if (this.childModelId !== null) {
      labelsCopy = readLabels(this.$store)(this.childModelId);
    } else {
      labelsCopy = readLabels(this.$store)(+this.$router.currentRoute.params.id);
    }
    const skipped: ILabel = {
      counter: 0,
      description: "Skip this example",
      id: -2,
      name: "skip",
      color: "#000000",
    };
    labelsCopy.push(skipped);
    return labelsCopy;
  }

  public async getExamples() {
    this.updateReason = null;
    this.loading = true;

    if (this.childModelId === null) {
      await api
        .getLabeledExamples(
          this.token,
          parseInt(this.$router.currentRoute.params.workspaceid, 10),
          this.modelId,
          this.filterId,
          this.page,
        )
        .then((r) => {
          this.loading = false;
          this.rows = r.data.result;
          this.numberHits = r.data.hits;
        })
        .catch((error) => {
          console.log("error when getting chosen dataset", error);
          this.loading = false;
        });
    } else {
      await api
        .getChildLabeledExamples(
          this.token,
          parseInt(this.$router.currentRoute.params.workspaceid, 10),
          this.childModelId,
          this.modelId,
          this.filterId,
          this.page,
        )
        .then((r) => {
          this.loading = false;
          this.rows = r.data.result;
          this.numberHits = r.data.hits;
        })
        .catch((error) => {
          console.log("error when getting chosen dataset", error);
          this.loading = false;
        });
    }
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  public async mounted() {
    console.log(this.childModelId);
    if (this.childModelId !== null) {
      let newHeader: any = {
        text: "Child label",
        sortable: true,
        value: "child_label",
        align: "center",
      };
      this.headers.splice(2, 0, newHeader);
    }
    this.getExamples();
  }

  public capitalizeFirstLetter(inputString: string): string {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY HH:mm");
  }

  public removeModel(id) {
    this.remove(id, true);
  }

  public getExportPrice(id) {
    this.exportprice(id);
  }

  public addToWorkspace(id) {
    this.addtoworkspace(id);
  }

  public statusCheck(model) {
    // returns true if status indicates training/uploading etc
    if (model?.status === "deployed" || model?.status === "ready") {
      if (model?.label_containers[0].status === "ready") {
        return false;
      }
      return true;
    }
    return true;
  }

  @Emit()
  public remove(id, dialogRemove) {}

  @Emit()
  public exportprice(id) {}

  @Emit()
  public addtoworkspace(id) {}
}
