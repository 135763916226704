
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { ILabel, IMetrics, IPredictions } from "@/interfaces";
import colors from "vuetify/es5/util/colors";
// headers: [
//           {
//             text: 'Dessert (100g serving)',
//             align: 'left',
//             sortable: false,
//             value: 'name'
//           },
//           { text: 'Calories', value: 'calories' },
//           { text: 'Fat (g)', value: 'fat' },
//           { text: 'Carbs (g)', value: 'carbs' },
//           { text: 'Protein (g)', value: 'protein' },
//           { text: 'Iron (%)', value: 'iron' }
//         ],
//         desserts: [
//           {
//             name: 'Frozen Yogurt',
//             calories: 159,
//             fat: 6.0,
//             carbs: 24,
//             protein: 4.0,
//             iron: '1%'
//           },

@Component
export default class ConfusionMatrix extends Vue {
  @Prop({ default: false })
  public metrics!: IMetrics;
  @Prop({ default: false })
  public labels!: ILabel[];
  @Prop({ default: false })
  public predictions!: any;
  @Prop({ default: false })
  public labelsByIds!: any;
  @Prop({ default: false })
  public labelsByName!: any;
  @Prop({ default: false })
  public modelId!: any;
  // public async mounted() {
  //   var myPlot = document.getElementById('plotly-cool');
  //   myPlot?.addEventListener("Click", function (data) {data)});
  // }

  public handlePlotClick(data) {

    const header =
      "Predicted label:<" +
      this.labelsByName[data.points[0].y.trim()].name +
      "> Actual: <" +
      this.labelsByName[data.points[0].x.trim()].name +
      ">";
    this.confusion(
      this.labelsByName[data.points[0].y.trim()].id,
      this.labelsByName[data.points[0].x.trim()].id,
      header,
    );
  }

  get labelCountList() {
    const res: number[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.labelsByIds[k].counter);
    });
    return res;
  }
  public hexToRGBA(hex: string, alpha: number = 1) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }
  public snakeToCamel(str) {
    return str.replace(/([-_][a-z])/g, (group) =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", ""),
    );
  }
  get labelAutomaticColorList() {
    const res: string[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(this.labelsByIds[k].color)].lighten2));
    });
    return res;
  }
  get labelManualColorList() {
    const res: string[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.hexToRGBA(colors[this.snakeToCamel(this.labelsByIds[k].color)].base));
    });
    return res;
  }
  get predictionCountList() {
    const res: number[] = [];
    this.metrics.keys.forEach((k) => {
      res.push(this.predictions[k]);
    });
    return res;
  }

  get labelAutomaticBarData() {
    return {
      x: this.labelNames,
      y: this.predictionCountList,
      type: "bar",
      name: "Automatic",
      colorscale: "Viridis",
      marker: {
        color: this.labelAutomaticColorList,
      },
    };
  }
  get labelManualBarData() {
    return {
      x: this.labelNames,
      y: this.labelCountList,
      type: "bar",
      name: "Manual",
      colorscale: "Viridis",
      marker: {
        color: this.labelManualColorList,
      },
    };
  }
  get labelBarLayout() {
    return {
      title: "Labels",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      barmode: "stack",
      xaxis: {
        title: {
          text: "Label",
        },
        ticks: "",
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: "Count",
        },
        ticks: "",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  get labelNames() {
    const mappedNames: string[] = [];
    this.metrics.keys.forEach((k) => {
      const filteredLabels = this.labels.filter((label) => label.id === k);
      if (filteredLabels.length > 0) {
        const name = filteredLabels[0].name;
        // Check if the name is numeric
        const isNumeric = !isNaN(Number(name)) && !isNaN(parseFloat(name));
        // Add a space after numeric values
        mappedNames.push(isNumeric ? `${name} ` : name);
      }
    });
    return mappedNames;
  }

  get annotations() {
    const annotationList: any[] = [];
    if (this.metrics.keys.length <= 30) {
      for (let i = this.metrics.cm.length - 1; i >= 0; i--) {
        for (let j = this.metrics.cm[i].length - 1; j >= 0; j--) {
          const currentValue = this.metrics.cm[i][j];
          const result = {
            xref: "x1",
            yref: "y1",
            x: j,
            y: i,
            text: currentValue,
            font: {
              family: "Arial",
              size: 12,
              color: "white",
            },
            showarrow: false,
          };
          annotationList.push(result);
        }
      }
    }

    return annotationList;
  }

  get texts() {
    let annotationList: any[][] = [];
    this.metrics.cm.forEach((dim0Value, dim0) => {
      let text: any[] = [];
      dim0Value.forEach((dim1Value, dim1) => {
        text = [
          ...text,
          `Predicted: ${this.labelNames[dim0Value.length - dim1 - 1]} <br />Actual: ${
            this.labelNames[dim0]
          } <br />Count: ${dim1Value}`,
        ];
      });
      annotationList = [...annotationList, text];
    });
    return annotationList;
  }

  get plotData() {
    const reversed = this.labelNames.slice();
    return [
      {
        z: this.metrics.cm,
        y: this.labelNames,
        x: reversed.reverse(),
        type: "heatmap",
        hoverinfo: "text",
        text: this.texts,
        colorscale: "Viridis",
      },
    ];
  }

  get layout() {
    return {
      title: "Confusion matrix",
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "rgba(0,0,0,0)",
      annotations: this.annotations,
      xaxis: {
        title: {
          text: "Predicted",
        },
        type: 'category',
        ticks: "",
        automargin: true,
        showgrid: true,
        zeroline: false,
        showline: true,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
      },
      yaxis: {
        title: {
          text: "Actual",
        },
        type: 'category',
        automargin: true,
        ticks: "",
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
      zaxis: {
        title: {
          text: "Count",
        },
        showgrid: true,
        zeroline: false,
        gridcolor: "#ffffff",
        linecolor: "#ffffff",
        gridwidth: 1,
        showline: true,
      },
    };
  }

  @Emit()
  public confusion(actualLabelId, predictedLabelId, header) {}
}
