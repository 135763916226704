
import { Component, Vue } from "vue-property-decorator";
import { readToken } from "@/store/main/getters";
import { api } from "@/api";
import { dispatchGetOnlyLabeled } from "@/store/model/actions";

@Component
export default class AdminUsers extends Vue {
  public email: string = "";
  public modelId: any = null;
  public genModelId: any = null;
  public workspaceId: any = null;
  public error: string = "";
  public loading: boolean = false;
  public modelType: string = "classifier";
  public success: boolean = false;
  public document: boolean = true;
  public overwritePrompts: boolean = false;
  public useCold: boolean = false;
  public areYouSure: boolean = false;
  public storeinfo: any = "";
  public nbrCleared: number = -1;
  public datasetId: any = null;
  public batchJobId: any = null;
  public daysBack: any = null;
  public refillId: any = null;
  public audioFileColumnId: any = null;
  public allNames: boolean = false;
  public generatedLink: string = "";
  public cluster: boolean = false;
  public clusterType: string = "all";
  public indexCloneResult: any = {};
  public shardSize: any = null;

  public min_cluster_size_min: number = 50;
  public min_cluster_size_max: number = 100;
  public min_samples_min: number = 1;
  public min_samples_max: number = 10;
  public epsilon_min: number = 10;
  public epsilon_max: number = 20;
  public downloadProgress: number = 0;
  public clusterKey: string = "all";
  public scrollSize: number = 10;
  public speedResult: any = null;
  public dateValue: any = null;

  public async clusterSetup() {
    this.loading = true;
    this.success = false;
    this.error = "";

    const clusterKey: string = encodeURI(this.clusterKey);

    const replacedString = clusterKey.replace(/&/g, "%26");

    console.log("look", replacedString);

    const hparams = {
      min_cluster_size_max: this.min_cluster_size_max,
      min_cluster_size_min: this.min_cluster_size_min,
      min_samples_min: this.min_samples_min,
      min_samples_max: this.min_samples_max,
      epsilon_min: this.epsilon_min,
      epsilon_max: this.epsilon_max,
    };

    await api
      .clusterSetup(this.token, this.datasetId, hparams, this.clusterType, replacedString)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }
  public async cloneGenAndClassifierModel() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .cloneGenAndClassifierModel(this.token, this.modelId, this.genModelId, this.workspaceId, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async removePreds() {
    this.loading = true;
    this.success = false;
    this.error = "";
    console.log(this.dateValue)
    await api
      .removePreds(this.token, this.datasetId, this.modelId, this.dateValue)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async restartBatchJob() {
    this.loading = true;
    this.success = false;
    this.error = "";
    console.log(this.dateValue)
    await api
      .restartBatchJob(this.token, this.batchJobId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async removePredsInTrain() {
    this.loading = true;
    this.success = false;
    this.error = "";
    console.log(this.dateValue)
    await api
      .removePredsInTrain(this.token, this.datasetId, this.modelId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async renameClusters() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .renameClusters(this.token, this.datasetId, this.clusterType, this.clusterKey)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async copyModelToWorkspaceAdmin() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .copyModelToWorkspaceAdmin(this.token, this.modelId, this.workspaceId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async runCsatSpeakerJob() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .runCsatSpeakerJob(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async runBackupJob() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .runBackupJob(this.token)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async censorDataset() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .censorDataset(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }


  public async startZeroShot() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startZeroShot(this.token, this.modelId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }
  
  public async startTraining() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startTraining(this.token, this.modelId, this.modelType)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async startSetup() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startSetup(this.token, this.datasetId, this.cluster)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }
  public async startRefillSetup() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startRefillSetup(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async harmonize() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .harmonize(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async startPromptGeneration() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startPromptGeneration(this.token, this.modelId, this.overwritePrompts)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async deletePromptResponses() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .deletePromptResponses(this.token, this.modelId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async createConversationItems() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .createConversationItems(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async deleteConversationItems() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .deleteConversationItems(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async exportExternalConversationLabelData() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .exportExternalConversationLabelData(this.token, this.datasetId, this.daysBack, this.useCold)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async startRemoveDuplicatePrompts() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startRemoveDuplicatePrompts(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async startRemovePromptGenerationFailed() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startRemovePromptGenerationFailed(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async startRemoveDuplicateUtterances() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startRemoveDuplicateUtterances(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async recountModels() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .recountModels(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async dumpAnalytics() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .dumpAnalytics(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async startDataRetention() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .startDataRetention(this.token)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async cloneDataset() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .cloneDataset(this.token, this.datasetId, this.workspaceId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }
  public async cloneIndexChangeSettings() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .cloneIndexChangeSettings(this.token, this.datasetId, this.shardSize)
      .then((res) => {
        this.indexCloneResult = res.data;
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }
  public async adminDownloadFile() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .adminDownloadFile(this.token, this.datasetId)
      .then((res) => {
        this.loading = false;
        this.success = true;
        const csvString = res.data;
        const a = window.document.createElement("a");
        a.setAttribute("href", "data:text/csv; charset=utf-8," + encodeURIComponent(csvString));
        a.setAttribute(
          "download",
          `dataset_${this.datasetId}_${new Date().toISOString().slice(0, 10)}.csv`,
        );
        window.document.body.appendChild(a);
        a.click();
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async speedTest() {
    this.loading = true;
    this.success = false;
    this.error = "";
    this.speedResult = null;
    await api
      .speedTest(this.token, this.datasetId, this.scrollSize)
      .then((res) => {
        this.speedResult = res.data;
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async adminCheckStoreRows() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .adminCheckStoreRows(this.token, this.datasetId)
      .then((res) => {
        this.storeinfo = res.data;
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async finalizeTranscribeDataset() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .finalizeTranscribeDataset(this.token, this.datasetId, this.audioFileColumnId)
      .then((res) => {
        this.storeinfo = res.data;
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async finalizeTranscribeDatasetRefill() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .finalizeTranscribeDatasetRefill(this.token, this.refillId)
      .then((res) => {
        this.storeinfo = res.data;
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async deleteDeletedLabels() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .deleteDeletedLabels(this.token, this.modelId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async inferenceOnNewData() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .inferenceOnNewData(this.token, this.modelId)
      .then((res) => {
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public async clearStore() {
    this.loading = true;
    this.success = false;
    this.error = "";
    await api
      .adminClearStore(this.token)
      .then((res) => {
        this.nbrCleared = res.data;
        this.loading = false;
        this.success = true;
      })
      .catch((error) => {
        this.error = error.response;
        console.log("ERROR", this.error);
        this.loading = false;
      });
  }

  public onDownloadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    this.downloadProgress = percentCompleted;
  }

  public async downloadWer() {
    this.downloadProgress = 0;
    this.loading = true;
    this.success = false;
    this.error = "";
    const config = {
      onDownloadrogress: this.onDownloadProgress,
    };
    await api
      .downloadWer(this.token, this.workspaceId, this.datasetId, config)
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/json" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `WER_${new Date().toISOString().slice(0, 10)}.json`;
        link.click();
        URL.revokeObjectURL(link.href);
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting dataset_exports", error);
        this.error = error.response;
        this.loading = false;
      });
  }

  get token() {
    return readToken(this.$store);
  }

  public async mounted() {}
}
