
import { Vue, Component } from "vue-property-decorator";

import { appName, urlToPage } from "@/env";
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readUserProfile,
  readWorkspace,
  readActiveWorkspace,
} from "@/store/main/getters";
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer } from "@/store/main/mutations";
import { dispatchUserLogOut, dispatchGetWorkspace } from "@/store/main/actions";
import { IUserProfile } from "@/interfaces";

const routeGuardMain = async (to, from, next) => {
  if (to.path === "/main") {
    next("/");
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public appName = appName;
  public mobileDialog = false;
  public urlToPage = urlToPage;
  public showAnalyticsTab = false;

  public async mounted() {
    if (this.isMobile) {
      this.mobileDialog = true;
    }
    await dispatchGetWorkspace(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    const profile = readUserProfile(this.$store);
    const workspace = readActiveWorkspace(this.$store);
    this.showAnalyticsTab =
      profile?.workspaces.some((ws) => ws.workspace_id === workspace && ws.role !== "labeler") ||
      false;
    Vue.prototype.$hj("identify", profile?.id, {
      name: profile?.full_name,
      email: profile?.email,
      user_id: profile?.id,
      workspace_id: workspace,
    });
  }
  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get activeWorkspace() {
    return readActiveWorkspace(this.$store);
  }

  get workspace() {
    return readWorkspace(this.$store);
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(this.$store, !readDashboardShowDrawer(this.$store));
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(this.$store, !readDashboardMiniDrawer(this.$store));
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
    location.reload();
  }

  get isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  }
}
