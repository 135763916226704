
import {
  dispatchGetConfusionMatrixItems,
  dispatchGetMetrics,
  dispatchGetPredictions,
  dispatchSetPreviewHeader,
} from "@/store/model/actions";
import {
  readLabelColorsByIds,
  readLabelCountsByIds,
  readLabels,
  readLabelsByIds,
  readLabelsByName,
  readMetrics,
  readModel,
  readPrediction,
  readPredictionsByIds,
  readPreviewItems,
  readTotalPredictions,
} from "@/store/model/getters";
import { Component, Vue } from "vue-property-decorator";

import { readHasAdminAccess, readToken } from "@/store/main/getters";

import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import RoundProgress from "@/components/RoundProgress.vue";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    ConfusionMatrix,
    RoundProgress,
    LabelChart,
  },
})
export default class Metrics extends Vue {
  public id: number = 1;
  public metricThreshold: number = 0.0;
  public showMetric: boolean = true;
  public loading: boolean = false;
  public advancedView: boolean = true; // simple
  public setType: string = "train"; // dictates wether you get train or val metrics
  public precisionDialog: boolean = false; // simple
  public totalValSet: number = 0;
  public totalSupport: number = 0;
  public loadingConfusion: boolean = false;

  public async mounted() {}
  public beforeRouteUpdate(to, from, next) {
    this.loadingConfusion = false;
    next();
  }

  public async getMetrics() {
    this.loading = true;

    // Determine labelId based on conditions
    let labelId: number | null;
    if (this.model!.label_containers[0].type !== "multi") {
        labelId = null;
    } else {
        labelId = parseInt(this.$router.currentRoute.params.labelid, 10);
    }

    // Determine if we are using the training set or validation set
    const trainSet: boolean = this.setType !== "validation";

    // Execute dispatchGetMetrics and dispatchGetPredictions concurrently
    await Promise.all([
        dispatchGetMetrics(this.$store, {
            modelId: parseInt(this.$router.currentRoute.params.id, 10),
            threshold: this.metricThreshold,
            labelId,
            train: trainSet,
        }),
        dispatchGetPredictions(this.$store, {
            modelId: parseInt(this.$router.currentRoute.params.id, 10),
            threshold: this.metricThreshold,
        }),
    ]);

    // Once both have completed, calculate totalSupport
    this.totalSupport = 0;
    this.labels!.forEach((v, k) => {
        this.totalSupport += this.metrics!.support[v.id];
    });

    console.log(this.totalSupport);
    console.log(this.totalValSet);

    this.loading = false;
}

  get labels() {
    const tmpLabels = readLabels(this.$store)(+this.$router.currentRoute.params.id);
    return tmpLabels;
  }

  public async switchSet() {
    this.metricThreshold = 0.0;
    await this.getMetrics();
    this.totalValSet = this.totalSupport;
  }

  get metrics() {
    return readMetrics(this.$store);
  }

  public async created() {
    await this.getMetrics();
    this.totalValSet = this.totalSupport;
  }

  /*    ________________________________________________     */
  /*                       GETTERS                           */
  /*    ________________________________________________     */
  get isAdmin() {
    return readHasAdminAccess;
  }

  get token() {
    return readToken(this.$store);
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public async getConfusionMatrixItems(actualLabelId, predictedLabelId, header) {
    this.loadingConfusion = true;
    dispatchSetPreviewHeader(this.$store, { header });
    let trainSet: boolean = true;
    if (this.setType === "validation") {
      trainSet = false;
    }

    await dispatchGetConfusionMatrixItems(this.$store, {
      modelId: this.model!.id,
      actualLabelId,
      predictedLabelId,
      threshold: this.metricThreshold,
      train: trainSet,
    })
      .then(() => {
        this.$router.push({
          path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.$router.currentRoute.params.id}/dashboard/metrics/preview?actual=${actualLabelId}&predicted=${predictedLabelId}&threshold=${this.metricThreshold}&train=${trainSet}`,
        });
      })
      .catch(() => {
        this.loadingConfusion = false;
      });
  }

  get previewItems() {
    return readPreviewItems(this.$store);
  }

  get totalPredictions() {
    return readTotalPredictions(this.$store);
  }
  get predictions() {
    return readPrediction(this.$store);
  }

  get predictionsByIds() {
    const predcounts = readPredictionsByIds(this.$store);
    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    if (this.labels) {
      this.labels!.forEach((l) => {
        res = { ...res, ...{ [l.id]: 0 } };
      });
    }

    return res;
  }

  get labelsByIds() {
    return readLabelsByIds(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.$router.currentRoute.params.id);
  }

  get labelColorsByIds() {
    const predcounts = readLabelColorsByIds(this.$store)(+this.$router.currentRoute.params.id);

    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    if (this.labels) {
      this.labels!.forEach((l) => {
        res = { ...res, ...{ [l.id]: 0 } };
      });
    }
    return res;
  }

  get labelCountsByIds() {
    const predcounts = readLabelCountsByIds(this.$store)(+this.$router.currentRoute.params.id);

    // check if empty (js things)
    if (Object.keys(predcounts).length !== 0) {
      return predcounts;
    }

    // create empty if not
    let res: any = {};
    if (this.labels) {
      this.labels!.forEach((l) => {
        res = { ...res, ...{ [l.id]: 0 } };
      });
    }
    return res;
  }

  /*    ________________________________________________     */
  /*                   Data tables                           */
  /*    ________________________________________________     */

  get tableDataRows() {
    const rows: any[] = [];
    let totalManual: number = 0;
    this.labels!.forEach((v, k) => {
      totalManual += this.labels![k].counter;
      rows.push({
        label: v.name,
        manual: this.labels![k].counter,
        total: this.labels![k].counter + this.predictionsByIds[v.id],
        predicted: this.predictionsByIds[v.id],
      });
    });

    return rows;
  }

  get totalProps() {
    let totalManual: number = 0;
    this.labels!.forEach((v, k) => {
      totalManual += this.labels![k].counter;
    });
    return {
      label: "Total",
      manual: totalManual,
      total: totalManual + this.totalPredictions,
      predicted: this.totalPredictions,
    };
  }

  get tableDataHeader() {
    return [
      {
        text: "Labels",
        align: "left",
        value: "label",
      },
      { text: "Manual", value: "manual", align: "right" },
      { text: "Predicted", value: "predicted", align: "right" },
      { text: "Total", value: "total", align: "right" },
    ];
  }

  /*    ________________________________________________     */
  /*                   metrics table                         */
  /*    ________________________________________________     */
  get tableRows() {
    const rows: any[] = [];
    if (this.metrics && this.metrics.accuracy !== null) {
      this.totalSupport = 0;
      this.labels!.forEach((v, k) => {
        this.totalSupport += this.metrics!.support[v.id];
        rows.push({
          label: v.name,
          precision: this.metrics!.precision[v.id],
          recall: this.metrics!.recall[v.id],
          accuracy: this.metrics!.accuracy[v.id],
          f1: this.metrics!.f1[v.id],
          threat_score: this.metrics!.threat_score[v.id],
          // top_n: this.metrics!.top_n[v.id],
          tn: this.metrics!.tn[v.id],
          tp: this.metrics!.tp[v.id],
          fn: this.metrics!.fn[v.id],
          fp: this.metrics!.fp[v.id],
          support: this.metrics!.support[v.id],
        });
      });
    }
    return rows;
  }
  get tableHeader() {
    return [
      {
        text: "Labels",
        align: "left",
        value: "label",
      },
      { text: "Accuracy", value: "accuracy", align: "right" },
      { text: "Precision", value: "precision", align: "right" },
      { text: "Recall", value: "recall", align: "right" },
      { 
        text: "F1",
        value: "f1",
        align: "right",
        tooltip: "F1 Score"
      },
      { 
        text: "Threat Score",
        value: "threat_score",
        align: "right",
        tooltip: "Threat Score"
      },
      // { text: "Top N", value: "top_n", align: "right" },
      { 
        text: "TP",
        value: "tp",
        align: "right",
        tooltip: "True Positives"
      },
      { 
        text: "FP",
        value: "fp",
        align: "right",
        tooltip: "False Positives"
      },
      { 
        text: "TN",
        value: "tn",
        align: "right",
        tooltip: "True Negatives"
      },
      { 
        text: "FN",
        value: "fn",
        align: "right",
        tooltip: "False Negatives"
      },
      { text: "Support", value: "support", align: "right" },
    ];
  }

  public toggleSimple() {
    this.advancedView = !this.advancedView;
  }
}
