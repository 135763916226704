
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { IItem, IItemUpdate, ILabel, ILabelCreate } from "@/interfaces";
import TextHighlight from "vue-text-highlight";

import { readModel, readFirstLabelContainer, readLabels } from "@/store/model/getters";
import { dispatchGetModels, dispatchCreateLabel } from "@/store/model/actions";
import { readUserProfile, readHasAdminAccess } from "@/store/main/getters";
import { readDatasetByStoreId } from "@/store/dataset/getters";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";
import TextEditor from "@/components/TextEditor.vue";
import { title } from "process";
import MarkdownIt from "markdown-it";

@Component({
  components: {
    TextHighlight,
    TextEditor,
  },
})
export default class ItemPreviewFullConv extends Vue {
  // internal properties

  @Prop({ default: false }) public item!: IItem;
  @Prop({ default: false }) public loadingexternal?: boolean;
  @Prop({ default: false }) public targetItems?: IItemUpdate[];
  @Prop({ default: true }) public flat!: boolean;
  @Prop({ default: false }) public change!: boolean;
  // This property (single) is cursed. Somehow you can't set it when you create the component.
  @Prop({ default: false }) public single!: boolean;
  @Prop({ default: false }) public summary!: string;
  @Prop({ default: false }) public reasoning!: string;
  @Prop({}) public highlights!: string[];
  @Prop({}) public recommendation: string;
  @Prop({ default: false }) isSelected!: boolean;

  public hide = false;
  public addButtonColor = "teal";
  public addLabel = false;
  public modelChoiceDialog: boolean = false;
  public searchTerm: string = "";
  /**
   * upload event...
   */
  public id: number = 1;
  public labelName: string = "";
  public labelDescription: string = "";
  public labelExamples: string[] = [];
  public activeLabelContainerId: number = -1;
  public activeLabelId: number = -1;
  public activeLabelExample: string = "";
  public loading: boolean = false;
  public e6: number = 0;
  public uploadError: any = null;
  public color: string = "green";
  public menu: boolean = false;
  public md = new MarkdownIt({
    html: true, // Enable HTML tags
    breaks: true, // Convert '\n' to <br>
    linkify: true, // Convert URLs to links
  });
  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];
  public userChanged: boolean = false;

  get dataset() {
    if (!this.item.store_id) {
      console.log("No store id");
      return null;
    }
    if (this.item.store_id.endsWith("_train")) {
      const cleanedStoreId = this.item.store_id.replace("_train", "");
      return readDatasetByStoreId(this.$store)(cleanedStoreId);
    }

    return readDatasetByStoreId(this.$store)(this.item.store_id);
  }

  public getMarked(text: string) {
    try {
      // Process markdown while preserving HTML tags
      const rendered = this.md.render(text);
      // Optional: Add additional security measures here if needed
      return rendered;
    } catch (e) {
      return text;
    }
  }

  public setNewText(newText: string, itemId: string) {
    this.item.plain_text = newText;
  }

  get hasConversationProperty() {
    if (this.dataset && this.dataset.meta_data && this.dataset.meta_data.columns) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.dataset.meta_data.columns)
        ? this.dataset.meta_data.columns
        : Object.values(this.dataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );

      if (conversationColumn) {
        console.log("Found conversation column:", conversationColumn);
        return conversationColumn;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  public mounted() {}

  get token() {
    return readToken(this.$store);
  }

  public async getConversationIdAndRoute(itemId: string) {
    this.clickedconversation(itemId);
    await api
      .getConversationIdFromItemId(
        this.token,
        parseInt(this.$router.currentRoute.params.id, 10),
        this.dataset!.store_id,
        itemId,
      )
      .then((r) => {
        const id = r.data.conversation_id;
        const start = r.data.start;
        window.open(
          `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${
            this.dataset!.id
          }/dashboard/browse?conversation_id=${id}&start=${start}`,
          "_blank",
        );
      })
      .catch((error) => {
        console.log("error attempting ", error);
      });
  }

  get firstLabelContainer() {
    return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  }
  public sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  get model() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  // Levenshtein distance function
  public levenshtein(a: string, b: string): number {
    const matrix = Array.from({ length: a.length + 1 }, () => new Array(b.length + 1).fill(0));

    for (let i = 0; i <= a.length; i++) {
      matrix[i][0] = i;
    }
    for (let j = 0; j <= b.length; j++) {
      matrix[0][j] = j;
    }
    for (let i = 1; i <= a.length; i++) {
      for (let j = 1; j <= b.length; j++) {
        const cost = a[i - 1] === b[j - 1] ? 0 : 1;
        matrix[i][j] = Math.min(
          matrix[i - 1][j] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j - 1] + cost,
        );
      }
    }

    return matrix[a.length][b.length];
  }

  // N-gram public
  public ngrams(s: string, n: number): Set<string> {
    const result = new Set<string>();

    for (let i = 0; i <= s.length - n; i++) {
      result.add(s.slice(i, i + n));
    }

    return result;
  }

  // Main search public
  public searchCategories(query: string, categories: ILabel[]): ILabel[] {
    const ngramSize = 3;
    const queryNgrams = this.ngrams(query.toLowerCase(), ngramSize);

    const scoredCategories = categories.map((category) => {
      const categoryNgrams = this.ngrams(category.name.toLowerCase(), ngramSize);
      const intersectionSize = [...queryNgrams].filter((x) => categoryNgrams.has(x)).length;
      const jaccardIndex =
        intersectionSize / (queryNgrams.size + categoryNgrams.size - intersectionSize);
      const levDist = this.levenshtein(query.toLowerCase(), category.name.toLowerCase());
      const score = jaccardIndex * (1 / (1 + levDist));
      return { ...category, score };
    });

    return scoredCategories.sort((a, b) => b.score - a.score);
  }
  get sortedLabels() {
    if (!this.labels) return this.labels;
    
    let sorted = this.labels.map((o) => ({ ...o }));
    sorted = sorted.filter((item) =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
    );

    if (this.item.predictions?.length && this.recommendation !== "cluster") {
      sorted.sort((a, b) => {
        const predA = this.item.predictions.find(p => p.label === a.id)?.prediction || 0;
        const predB = this.item.predictions.find(p => p.label === b.id)?.prediction || 0;
        return predB - predA;
      });
    }

    if (sorted.length === 0) {
      sorted = this.labels.map((o) => ({ ...o }));
      sorted = this.searchCategories(this.searchTerm, sorted);
    }

    return sorted;
  }
  public changeColor(color) {}

  public getImageUrl(text) {
    if (text.lastIndexOf("[]()**") > -1) {
      return text.substring(text.lastIndexOf("[]()**") + 6, text.length);
    } else {
      return "";
    }
  }

  public removeImageUrl(text) {
    if (text.lastIndexOf("[]()**") > -1) {
      return text.substring(0, text.lastIndexOf("[]()**"));
    } else {
      return text;
    }
  }

  get colorList() {
    if (this.remainingColors.length > 0) {
      this.color = this.remainingColors[0];
      return this.remainingColors;
    } else {
      this.color = this.colors[Math.floor(Math.random() * this.color.length)];
      return this.colors;
    }
  }

  get remainingColors() {
    let remainingColors: string[] = [];
    if (this.labels) {
      this.colors.forEach((c) => {
        const colorExists = this.labels!.filter((label) => label.color === c);
        if (colorExists.length === 0) {
          remainingColors.push(c);
        }
      });
    } else {
      remainingColors = this.colors;
    }

    remainingColors = remainingColors
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

    return remainingColors;
  }

  get labelNameExists(): boolean {
    if (this.labels) {
      return this.labels!.filter((label) => label.name === this.labelName).length > 0;
    }
    return false;
  }

  get labelNameErrors(): string[] {
    if (this.labelNameExists) {
      return ["This name does already exist"];
    }
    return [];
  }

  get labelDescriptionErrors(): string[] {
    if (this.labelDescription.length > 3000) {
      return ["Label description is too long"];
    }
    return [];
  }

  get labelIsClean(): boolean {
    if (
      this.labelName.length < 1 ||
      this.labelName.length > 40 ||
      this.labelDescription.length > 3000
    ) {
      return false;
    }
    if (this.labelNameExists) {
      return false;
    }
    return true;
  }

  public async createLabelAPI() {
    this.addLabel = false;

    if (this.activeLabelExample.length) {
      this.labelExamples.push(this.activeLabelExample);
    }
    this.activeLabelExample = "";
    const newLabel: ILabelCreate = {
      name: this.labelName,
      description: this.labelDescription,
      color: this.color,
      examples: this.labelExamples,
    };
    await dispatchCreateLabel(this.$store, {
      labelContainerId: this.firstLabelContainer!.id,
      label: newLabel,
    })
      .then((r) => {
        setTimeout(() => {
          const ids: number[] = this.labels.map((l) => {
            return l.id;
          });
          const going = Math.max(...ids);
          this.labelName = "";
          this.labelDescription = "";
          this.uploadError = null;
          this.labelExamples = [];
          if (this.labels.length === 2 && this.firstLabelContainer!.type === "single") {
            this.changed(0, 0, 0);
          }
          // this.$router.push({
          //   path: `/main/${this.$router.currentRoute.params.workspaceid}/classification/
          // ${this.$router.currentRoute.params.id}/label/labels/${going}/example`,
          // });
        }, 200);
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError);
        this.uploadError = uploadError.response;
      });
  }

  public labelById(labelId) {
    return this.labels.filter((label) => label.id === labelId);
  }

  public async getModels() {
    await dispatchGetModels(this.$store, {
      id: +this.$router.currentRoute.params.workspaceid,
    });
  }

  public removeLabelExample(toRemove) {
    let tmpExamples = this.labelExamples.slice();
    tmpExamples = tmpExamples.filter((example) => example !== toRemove);
    this.labelExamples = tmpExamples;
  }

  public addLabelExample() {
    if (this.activeLabelExample === "") {
      this.createLabelAPI();
    } else if (!this.labelExamples.includes(this.activeLabelExample)) {
      this.labelExamples.push(this.activeLabelExample);
      this.activeLabelExample = "";
    }
  }

  get maxConfidence() {
    if (this.item!.hasOwnProperty("predictions") && this.item!.predictions.length !== 0) {
      const ids: number[] = this.item!.predictions.map((l) => {
        return l.prediction;
      });
      const going = Math.max(...ids);
      return going;
    }
    return 1;
  }
  get thresholdConfidence() {
    return 1 / this.labels!.length + 0.15;
  }

  get labels() {
    return readLabels(this.$store)(+this.$router.currentRoute.params.id);
  }

  get glowOrNot() {
    if (
      this.maxConfidence <= this.thresholdConfidence &&
      this.change &&
      this.recommendation !== "validation" &&
      this.recommendation !== "cluster"
    ) {
      return "glowbox";
    }
    return "";
  }
  get targetLabel() {
    if (this.hide) return { color: "grey", name: "ignore" };
    if (!this.userChanged && (
      this.recommendation === "validation" ||
      this.recommendation === "cluster" ||
      this.recommendation === "plain_text_search" ||
      this.recommendation === "synthetic"
    )) {
      return { color: "grey", name: "Pick a label" };
    }

    const filteredItems = this.targetItems?.filter(
      (targetItem) => targetItem.id === this.item.id
    );
    
    if (filteredItems?.length) {
      return this.getLabelById(filteredItems[0].user_labels[0].label);
    }
    
    return null;
  }

  get hideThis() {
    if (this.hide) {
      return "background-color: rgb(255 198 198);";
    }
    return "";
  }

  public getLabelById(id) {
    const filteredItems = this.labels!.filter((label) => label.id === id);
    if (filteredItems.length > 0) {
      return filteredItems[0];
    }
    return 0;
  }

  @Emit()
  public clickedconversation(itemid) {
    console.log("clickedconversation", itemid);
    this.$emit(itemid);
  }
  @Emit()
  public changed(labelid, itemid, storeid) {
    if (labelid !== 0 && itemid !== 0 && storeid !== 0) {
      this.userChanged = true;
    }
    if (labelid !== -2) {
      this.hide = false;
    }
  }
}
