import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IModelCreate,
  ILabelContainerUpdate,
  ILabelContainerCreate,
  ILabelCreate,
  IRecommendation,
  IRecommendationUpdate,
  ITargetLabel,
  ILabelContainerUpdateUtteranceSpan,
  IFilterPerDataset,
  IModelAlwaysDeployed,
} from "@/interfaces";
import { State } from "../state";
import { ModelState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetMetrics,
  commitSetModels,
  commitSetModel,
  commitSetItems,
  commitSetPreviewItems,
  commitDeletemodel,
  commitSetPredictions,
  commitSetAccuracy,
  commitSetRecommendation,
  commitSetPreviewHeader,
  commitSetConnectedDatasets,
} from "./mutations";

import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import { AxiosError } from "axios";

type MainContext = ActionContext<ModelState, State>;

export const actions = {
  /* ______________________

    Models
    ________________________
    */

  async actionGetModels(context: MainContext, payload: { id: number }) {
    try {
      const response = await api.getModels(context.rootState.main.token, payload.id);
      if (response) {
        commitSetModels(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  /*
    async actionUpdateModel(context: MainContext, payload: { id: number, Model: IModelUpdate }) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateModel(context.rootState.main.token, payload.id, payload.Model),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetModel(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Model successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    */
  async actionCreateModel(
    context: MainContext,
    payload: {
      workspaceId: number;
      model: IModelCreate;
      datasetId: number;
      modelFilters: any[] | boolean;
    },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createModelFromUpload(
            context.rootState.main.token,
            payload.workspaceId,
            payload.datasetId,
            payload.modelFilters,
            payload.model,
          ),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetModel(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Model successfully created", color: "success" });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteModel(context: MainContext, payload: { workspaceId: number; modelId: number }) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await Promise.all([
        api.deleteModel(context.rootState.main.token, payload.workspaceId, payload.modelId),
        await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
      ]);
      commitDeletemodel(context, payload.modelId);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Model successfully removed", color: "success" });
    } catch (error) {
      console.log(error);
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionUpdateModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number; model: IModelCreate },
  ) {
    // We can use IModelCreate
    try {
      await api
        .updateModel(
          context.rootState.main.token,
          payload.workspaceId,
          payload.modelId,
          payload.model,
        )
        .then((response) => {
          commitSetModel(context, response.data);
          commitAddNotification(context, {
            content: "Successfully updated model",
            color: "success",
          });
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionRetrainModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    // We can use IModelCreate
    try {
      await api
        .retrain(context.rootState.main.token, payload.workspaceId, payload.modelId)
        .then((response) => {
          commitSetModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionShareModel(context: MainContext, payload: { workspaceId: number; modelId: number }) {
    try {
      await api
        .shareModel(context.rootState.main.token, payload.workspaceId, payload.modelId)
        .then((response) => {
          commitSetModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionUnshareModel(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      await api
        .unshareModel(context.rootState.main.token, payload.workspaceId, payload.modelId)
        .then((response) => {
          commitSetModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionResetModel(context: MainContext, payload: { workspaceId: number; modelId: number }) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.resetModel(context.rootState.main.token, payload.workspaceId, payload.modelId),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetModel(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "Model successfully reset", color: "success" });
    } catch (error) {
      console.log(error);
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionDeleteLabel(
    context: MainContext,
    payload: { labelContainerId: number; labelId: number },
  ) {
    //     try {
    //         const loadingNotification = { content: 'saving', showProgress: true };
    //         commitAddNotification(context, loadingNotification);
    //         const response = (await Promise.all([
    //             api.deleteLabel(context.rootState.main.token, payload.labelContainerId, payload.labelId),
    //             // await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
    //         ]));
    //         commitRemoveNotification(context, loadingNotification);
    //         commitAddNotification(context, { content: 'Label successfully removed', color: 'success' });
    //     } catch (error) {
    //         console.log(error.response);
    //         await dispatchCheckApiError(context, error);
    //         commitAddNotification(context, { content: error.response.data.detail, color: 'error' });
    //         return Promise.reject(error);
    //     }
    // },
    try {
      await api
        .deleteLabel(context.rootState.main.token, payload.labelContainerId, payload.labelId)
        .then((response) => {
          commitSetModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  /* ______________________

    LabelContainers
    ________________________
    */

  async actionCreateLabelContainer(
    context: MainContext,
    payload: { modelId: number; labelContainer: ILabelContainerCreate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await api
        .createLabelContainer(context.rootState.main.token, payload.modelId, payload.labelContainer)
        .then((response) => {
          commitSetModel(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, {
            content: "Task successfully created",
            color: "success",
          });
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionUpdateLabelContainer(
    context: MainContext,
    payload: { modelId: number; labelContainerId: number; labelContainer: ILabelContainerUpdate },
  ) {
    // try {
    //     const loadingNotification = { content: 'saving', showProgress: true };
    //     commitAddNotification(context, loadingNotification);
    //     await api.updateLabelContainer(context.rootState.main.token,
    // payload.modelId, payload.labelContainerId, payload.labelContainer).then((response) => {
    //         commitSetModel(context, response.data);
    //         commitRemoveNotification(context, loadingNotification);
    //         commitAddNotification(context, { content: 'Task successfully Updated', color: 'success' });
    //     }).catch((error) => {
    //         dispatchCheckApiError(context, error);
    //         return Promise.reject(error);
    //     });
    // } catch (error) {
    //     await dispatchCheckApiError(context, error);
    //     return Promise.reject(error);
    // }
    try {
      await api
        .updateLabelContainer(
          context.rootState.main.token,
          payload.modelId,
          payload.labelContainerId,
          payload.labelContainer,
        )
        .then((response) => {
          commitSetModel(context, response.data);
          commitAddNotification(context, {
            content: "Successfully updated model",
            color: "success",
          });
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionUpdateLabelContainerUtteranceSpan(
    context: MainContext,
    payload: {
      modelId: number;
      labelContainerId: number;
      labelContainer: ILabelContainerUpdateUtteranceSpan;
    },
  ) {
    try {
      await api
        .updateLabelContainerUtteranceSpan(
          context.rootState.main.token,
          payload.modelId,
          payload.labelContainerId,
          payload.labelContainer,
        )
        .then((response) => {
          commitSetModel(context, response.data);
          commitAddNotification(context, {
            content: "Successfully updated utterance span",
            color: "success",
          });
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  /* ______________________

    Labels
    ________________________
    */

  async actionCreateLabel(
    context: MainContext,
    payload: { labelContainerId: number; label: ILabelCreate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await api
        .createLabel(context.rootState.main.token, payload.labelContainerId, payload.label)
        .then((response) => {
          commitSetModel(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, {
            content: "Label successfully created",
            color: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionCreateLabelExample(
    context: MainContext,
    payload: { labelContainerId: number; labelId: number; data: string },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await api
        .createLabelExample(
          context.rootState.main.token,
          payload.labelContainerId,
          payload.labelId,
          payload.data,
        )
        .then((response) => {
          // commitSetModel(context, response.data);
          commitRemoveNotification(context, loadingNotification);
          commitAddNotification(context, {
            content: "example successfully added",
            color: "success",
          });
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionUpdateLabel(
    context: MainContext,
    payload: { labelContainerId: number; labelId: number; label: ILabelCreate },
  ) {
    try {
      await api
        .updateLabel(
          context.rootState.main.token,
          payload.labelContainerId,
          payload.labelId,
          payload.label,
        )
        .then((response) => {
          commitSetModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  //     try {
  //         const loadingNotification = { content: 'saving', showProgress: true };
  //         commitAddNotification(context, loadingNotification);
  //         await api.updateLabel(context.rootState.main.token,
  // payload.labelContainerId, payload.labelId, payload.label).then((response) => {
  //             commitSetModel(context, response.data);
  //             commitRemoveNotification(context, loadingNotification);
  //             commitAddNotification(context, { content: 'Label successfully updated', color: 'success' });
  //         }).catch((error) => {
  //             dispatchCheckApiError(context, error);
  //             return Promise.reject(error);
  //         });
  //     } catch (error) {
  //         await dispatchCheckApiError(context, error);
  //         return Promise.reject(error);
  //     }
  // },
  /* ______________________

    Items
    ________________________
    */

  async actionGetRecommendation(
    context: MainContext,
    payload: { modelId: number; filterPerDataset: IFilterPerDataset, lastId: string | null },
  ) {
    try {
      await api
        .getRecommendation(
          context.rootState.main.token,
          payload.modelId,
          payload.filterPerDataset,
          payload.lastId,
        )
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionClearRecommendation(context: MainContext) {
    const emptyLabel: ITargetLabel = {
      label: -1,
      label_container: -1,
    };
    const emptyRec: IRecommendation = {
      recommendation_type: "",
      recommendation: "",
      target: emptyLabel,
      items: [],
    };
    commitSetRecommendation(context, emptyRec);
  },

  async actionGetSyntheticRecommendation(
    context: MainContext,
    payload: { modelId: number; labelId: number },
  ) {
    try {
      await api
        .syntheticData(context.rootState.main.token, payload.modelId, payload.labelId)
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGetCorrectionRecommendation(
    context: MainContext,
    payload: { modelId: number, filterPerDataset: IFilterPerDataset },
  ) {
    try {
      await api
        .corrections(context.rootState.main.token, payload.modelId, payload.filterPerDataset)
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGetClusterRecommendation(
    context: MainContext,
    payload: { modelId: number; clusterId: number; searchString: string },
  ) {
    try {
      await api
        .clusterById(
          context.rootState.main.token,
          payload.modelId,
          payload.clusterId,
          payload.searchString,
        )
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGetSyntheticPromptRecommendation(
    context: MainContext,
    payload: { modelId: number; prompt: string },
  ) {
    try {
      await api
        .syntheticPromptData(context.rootState.main.token, payload.modelId, payload.prompt)
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  //     try {
  //         const response = await api.getRecommendation(
  //  context.rootState.main.token, payload.modelId, payload.languageFilter);
  //         if (response) {
  //             commitSetRecommendation(context, response.data);
  //         }
  //     } catch (error) {
  //         await dispatchCheckApiError(context, error);
  //     }
  // },
  async actionGetSearchRecommendation(
    context: MainContext,
    payload: { modelId: number; plainText: string, filterPerDataset: IFilterPerDataset },
  ) {
    try {
      await api
        .plainTextSearchRecommendation(
          context.rootState.main.token,
          payload.modelId,
          payload.plainText,
          payload.filterPerDataset,
        )
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionGetValidationRecommendation(context: MainContext, payload: { modelId: number, filterPerDataset: IFilterPerDataset}) {
    try {
      await api
        .getValidationRecommendation(context.rootState.main.token, payload.modelId, payload.filterPerDataset)
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionGetItems(context: MainContext, payload: { modelId: number }) {
    try {
      const response = await api.getItem(context.rootState.main.token, payload.modelId);
      if (response) {
        commitSetItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  async actionGetItem(context: MainContext, payload: { modelId: number }) {
    try {
      const response = await api.getItem(context.rootState.main.token, payload.modelId);
      if (response) {
        commitSetItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetLastItem(context: MainContext, payload: { modelId: number }) {
    try {
      await api
        .getLast(context.rootState.main.token, payload.modelId)
        .then((response) => {
          commitSetRecommendation(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },

  async actionAddtemLabels(
    context: MainContext,
    payload: { modelId: number; labels: IRecommendationUpdate },
  ) {
    try {
      const response = (
        await Promise.all([
          api.postItemLabel(context.rootState.main.token, payload.modelId, payload.labels),
          await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetModel(context, response.data);
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  /* ______________________

    Metrics
    ________________________
    */

  async actionGetMetrics(
    context: MainContext,
    payload: { modelId: number; threshold: number; labelId: number | null; train: boolean },
  ) {
    if (payload.labelId === null) {
      try {
        const response = await api.getMetrics(
          context.rootState.main.token,
          payload.modelId,
          payload.threshold,
          payload.train,
        );
        if (response) {
          commitSetMetrics(context, response.data);
        }
      } catch (error) {
        await dispatchCheckApiError(context, error as AxiosError);
      }
    } else {
      try {
        const response = await api.getMultiLabelMetrics(
          context.rootState.main.token,
          payload.modelId,
          payload.threshold,
          payload.labelId,
          payload.train,
        );
        if (response) {
          commitSetMetrics(context, response.data);
        }
      } catch (error) {
        await dispatchCheckApiError(context, error as AxiosError);
      }
    }
  },

  /* ______________________

    Preview
    ________________________
    */

  async actionGetConfusionMatrixItems(
    context: MainContext,
    payload: {
      modelId: number;
      actualLabelId: number;
      predictedLabelId: number;
      threshold: number;
      train: boolean;
    },
  ) {
    try {
      const response = await api.getConfusionMatrixItems(
        context.rootState.main.token,
        payload.modelId,
        payload.actualLabelId,
        payload.predictedLabelId,
        payload.threshold,
        payload.train,
      );
      if (response) {
        commitSetPreviewItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  actionSetPreviewHeader(
    context: MainContext,
    payload: {
      header: string;
    },
  ) {
    commitSetPreviewHeader(context, payload.header);
  },

  async actionGetPredictedItems(
    context: MainContext,
    payload: {
      modelId: number;
      predictedLabelId: number;
      threshold: number;
    },
  ) {
    try {
      const response = await api.getPredictedItems(
        context.rootState.main.token,
        payload.modelId,
        payload.predictedLabelId,
        payload.threshold,
      );
      if (response) {
        commitSetPreviewItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  /* ______________________

    Predictions
    ________________________
    */

  async actionGetPredictions(
    context: MainContext,
    payload: { modelId: number; threshold: number },
  ) {
    try {
      const response = await api.getPredictions(
        context.rootState.main.token,
        payload.modelId,
        payload.threshold,
      );
      if (response) {
        commitSetPredictions(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },
  async actionGetPredictionItem(
    context: MainContext,
    payload: { modelId: number; labelContainerId: number; labelId: number },
  ) {
    try {
      const response = await api.getPredictionItems(
        context.rootState.main.token,
        payload.modelId,
        payload.labelContainerId,
        payload.labelId,
      );
      if (response) {
        commitSetItems(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  /* ______________________

    Connected Datasets
    ________________________
    */
  async actionGetConnectedDatasets(
    context: MainContext,
    payload: { workspaceId: number; modelId: number },
  ) {
    try {
      const response = await api.getConnectedDatasets(
        context.rootState.main.token,
        payload.workspaceId,
        payload.modelId,
      );
      if (response) {
        commitSetConnectedDatasets(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  /* ______________________

    Accuracy
    ________________________
    */

  async actionGetAccuracy(context: MainContext, payload: { modelId: number }) {
    try {
      const response = await api.getAccuracy(context.rootState.main.token, payload.modelId);
      if (response) {
        commitSetAccuracy(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
    }
  },

  /* ______________________

    export
    ________________________
    */
  async actionGetOriginal(
    context: MainContext,
    payload: { workspaceId: number; datasetId: number; datasetName: string; config: any },
  ) {
    try {
      await api
        .getOriginal(
          context.rootState.main.token,
          payload.workspaceId,
          payload.datasetId,
          payload.config,
        )
        .then((response) => {
          // const csvString = response.data;
          // const a = window.document.createElement('a');
          // a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(csvString));
          // a.setAttribute('download', `${payload.datasetName}_${new Date().toISOString().slice(0, 10)}.csv`);
          // window.document.body.appendChild(a);
          // a.click();
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${payload.datasetName}_${new Date().toISOString().slice(0, 10)}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
          return Promise.resolve(response);
        })
        .catch((error) => {
          console.log("RESPONSE DATA deta", error.response.data);
          console.log("error actions", error.response);
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionGetInternal(
    context: MainContext,
    payload: { workspaceId: number; modelId: number; config: any },
  ) {
    try {
      await api
        .getInternal(
          context.rootState.main.token,
          payload.workspaceId,
          payload.modelId,
          payload.config,
        )
        .then((response) => {
          // const csvString = response.data;
          // const a = window.document.createElement('a');
          // a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(csvString));
          // a.setAttribute('download', `${payload.datasetName}_${new Date().toISOString().slice(0, 10)}.csv`);
          // window.document.body.appendChild(a);
          // a.click();
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Internal_dataset_from_model_${JSON.stringify(
            payload.modelId,
          )}_${new Date().toISOString().slice(0, 10)}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
          return Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error actions", error);
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionGetOnlyLabeled(context: MainContext, payload: { modelId: number }) {
    try {
      await api
        .getOnlyLabeled(context.rootState.main.token, payload.modelId)
        .then((response) => {
          // const csvString = response.data;
          // const a = window.document.createElement('a');
          // a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent(csvString));
          // a.setAttribute('download', `${payload.datasetName}_${new Date().toISOString().slice(0, 10)}.csv`);
          // window.document.body.appendChild(a);
          // a.click();
          const blob = new Blob([response.data], { type: "text/csv" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `labeled_items_from_model_${JSON.stringify(payload.modelId)}_${new Date()
            .toISOString()
            .slice(0, 10)}.csv`;
          link.click();
          URL.revokeObjectURL(link.href);
          return Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error actions", error);
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
  async actionUpdateModelAlwaysDeployed(
    context: MainContext,
    payload: { workspaceId: number; modelId: number; alwaysDeployed: IModelAlwaysDeployed },
  ) {
    // We can use IModelCreate
    try {
      await api
        .updateModelAlwaysDeployed(
          context.rootState.main.token,
          payload.workspaceId,
          payload.modelId,
          payload.alwaysDeployed,
        )
        .then((response) => {
          commitSetModel(context, response.data);
        })
        .catch((error) => {
          dispatchCheckApiError(context, error);
          return Promise.reject(error);
        });
    } catch (error) {
      await dispatchCheckApiError(context, error as AxiosError);
      return Promise.reject(error);
    }
  },
};

const { dispatch } = getStoreAccessors<ModelState, State>("");

export const dispatchCreateModel = dispatch(actions.actionCreateModel);
export const dispatchGetModels = dispatch(actions.actionGetModels);
// export const dispatchUpdateModel = dispatch(actions.actionUpdateModel);
export const dispatchDeleteModel = dispatch(actions.actionDeleteModel);
export const dispatchResetModel = dispatch(actions.actionResetModel);
export const dispatchUpdateModel = dispatch(actions.actionUpdateModel);
export const dispatchRetrainModel = dispatch(actions.actionRetrainModel);
export const dispatchShareModel = dispatch(actions.actionShareModel);
export const dispatchUnshareModel = dispatch(actions.actionUnshareModel);

export const dispatchCreateLabelContainer = dispatch(actions.actionCreateLabelContainer);
export const dispatchUpdateLabelContainer = dispatch(actions.actionUpdateLabelContainer);
export const dispatchUpdateLabelContainerUtteranceSpan = dispatch(
  actions.actionUpdateLabelContainerUtteranceSpan,
);

export const dispatchCreateLabel = dispatch(actions.actionCreateLabel);
export const dispatchUpdateLabel = dispatch(actions.actionUpdateLabel);
export const dispatchCreateLabelExample = dispatch(actions.actionCreateLabelExample);
export const dispatchDeleteLabel = dispatch(actions.actionDeleteLabel);

export const dispatchGetItems = dispatch(actions.actionGetItems);
export const dispatchGetItem = dispatch(actions.actionGetItem);
export const dispatchGetLastItem = dispatch(actions.actionGetLastItem);

export const dispatchGetConfusionMatrixItems = dispatch(actions.actionGetConfusionMatrixItems);
export const dispatchGetPredictedItems = dispatch(actions.actionGetPredictedItems);

export const dispatchSetPreviewHeader = dispatch(actions.actionSetPreviewHeader);

export const dispatchAddItemLabels = dispatch(actions.actionAddtemLabels);

export const dispatchGetPredictions = dispatch(actions.actionGetPredictions);
export const dispatcGetPredictionItem = dispatch(actions.actionGetPredictionItem);

export const dispatchGetAccuracy = dispatch(actions.actionGetAccuracy);

export const dispatchGetOriginal = dispatch(actions.actionGetOriginal);
export const dispatchGetInternal = dispatch(actions.actionGetInternal);
export const dispatchGetOnlyLabeled = dispatch(actions.actionGetOnlyLabeled);

export const dispatchGetRecommendation = dispatch(actions.actionGetRecommendation);
export const dispatchClearRecommendation = dispatch(actions.actionClearRecommendation);
export const dispatchGetSyntheticRecommendation = dispatch(
  actions.actionGetSyntheticRecommendation,
);
export const dispatchGetCorrectionRecommendation = dispatch(
  actions.actionGetCorrectionRecommendation,
);
export const dispatchGetClusterRecommendation = dispatch(actions.actionGetClusterRecommendation);
export const dispatchGetSyntheticPromptRecommendation = dispatch(
  actions.actionGetSyntheticPromptRecommendation,
);
export const dispatchGetValidationRecommendation = dispatch(
  actions.actionGetValidationRecommendation,
);

export const dispatchGetSearchRecommendation = dispatch(actions.actionGetSearchRecommendation);

export const dispatchGetMetrics = dispatch(actions.actionGetMetrics);

export const dispatchGetConnectedDatasets = dispatch(actions.actionGetConnectedDatasets);

export const dispatchUpdateModelAlwaysDeployed = dispatch(actions.actionUpdateModelAlwaysDeployed);
