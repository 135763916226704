
import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class ToolbarButton extends Vue {
  @Prop(Boolean) public disabled: boolean | false;
  @Prop(String) public label: string | "";
  @Prop(String) public text: string | "";
  @Prop(String) public icon: string | "";
  @Prop(String) public to: string | "";
  @Prop(String) public iconcolor: string | "";
}
