
import { Component, Vue, Watch } from "vue-property-decorator";
import { IDataset } from "@/interfaces";
import { readDataset, readDatasets } from "@/store/dataset/getters";
import { dispatchGetGenerativeConnectedDatasets } from "@/store/generative_model/actions";

import {
  readGenerativeModel,
  readGenerativeModels,
  readConnectedDatasets,
} from "@/store/generative_model/getters";
import { readModels, readModel } from "@/store/model/getters";
import {
  dispatchShareGenerativeModel,
  dispatchRetrainGenerativeModel,
  dispatchGetGenerativeModels,
} from "@/store/generative_model/actions";

import { readToken } from "@/store/main/getters";
import { api } from "@/api";

import ItemPreview from "@/components/ItemPreview.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import InternalDatasetCard from "@/components/InternalDatasetCard.vue";
import LabelBar from "@/components/LabelBar.vue";
import LabelChart from "@/components/LabelChart.vue";
import LabelPieChart from "@/components/LabelPieChart.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import DatasetCard from "@/components/DatasetCard.vue";
import ItemPreviewConfusionMatrix from "@/components/ItemPreviewConfusionMatrix.vue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { filter } from "vue/types/umd";

@Component({
  components: {
    ItemPreview,
    ModelProgress,
    LabelBar,
    LabelChart,
    LabelPieChart,
    ToolbarButton,
    RoundProgress,
    DatasetCard,
    InternalDatasetCard,
    ItemPreviewConfusionMatrix,
  },
})
export default class GenerativeDashboardView extends Vue {
  public dialogMissingExamples: boolean = false;
  public metricThreshold: number = 0.0;
  public text: string = "";
  public modelPredictions: any[] = [];
  public inferenceError: any = null;
  public retrainError: any = null;
  public loading: boolean = false;
  public availableDatasets: any[] = [];
  public dialogConnect: boolean = false;
  public dialogOverwrite: boolean = false;
  public e6: number = 0;

  public inferenceTestResponse: string = "";

  public loadingScreen: boolean = false;
  public keyUpdate: number = Math.floor(Math.random() * (100 + 1));
  public tabModel: number = 0;

  public chosenDatasetId: number;
  public uploadError: any = null;

  public dialogDisconnect: boolean = false;
  public predictedDialog: boolean = false;
  public predictedLabel: string | null = null;
  public dialogDeploy: boolean = false;
  public isDeploy: boolean = false;

  public mediaRecorder: MediaRecorder;
  public chunkus: Blob[] = [];
  public recording: boolean = false;
  public loadingTranscript: boolean = false;
  public countDown: number = 10;
  public timeOutId: any;
  public deployError: any = null;

  public expandedRow: number | null = null;
  public userExampleCount: any = {};

  public intervalId: number | null = null;

  public labels: any = [];

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  public navigateToModel(id: number) {
    const workspaceId = this.$router.currentRoute.params.workspaceid;
    const path = `/main/${workspaceId}/generative/${id}/dashboard/dashboard`;
    this.$router.push(path);
  }

  public startTuning() {
    const id = this.$router.currentRoute.params.id;
    const workspaceId = this.$router.currentRoute.params.workspaceid;
    const path = `/main/${workspaceId}/generative/${id}/dashboard/prompt_tuning`;
    this.$router.push(path);
  }

  public async runPrompt(overwritePrompts: boolean) {
    this.loadingScreen = true;
    await api
      .runGenerativePrompt(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        overwritePrompts,
      )
      .then((r) => {
        this.loadingScreen = false;
        this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/generative/view`);
      })
      .catch((error) => {
        this.loadingScreen = false;
        console.log("error when starting training", error);
        this.uploadError = error.response;
      });
  }

  public async undeployGenerativeModel() {
    this.loadingScreen = true;
    await api
      .undeployGenerativeModel(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.loadingScreen = false;
        this.fetchModels();
      })
      .catch((error) => {
        this.loadingScreen = false;
        console.log("error when starting training", error);
        this.uploadError = error.response;
        this.fetchModels();
      });
  }

  public async deletePromptGenerations() {
    this.loadingScreen = true;
    await api
      .deletePromptGenerations(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.loadingScreen = false;
        this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/generative/view`);
      })
      .catch((error) => {
        this.loadingScreen = false;
        console.log("error when starting training", error);
        this.uploadError = error.response;
      });
  }

  public async startTraining() {
    this.loadingScreen = true;

    await api
      .startLLMTraining(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
      )
      .then((r) => {
        this.loadingScreen = false;
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/view`,
        );
      })
      .catch((error) => {
        this.loadingScreen = false;
        console.log("error when starting training", error);
        this.uploadError = error.response;
      });
  }

  public getFilterColumnName(columnId: number, dataset: IDataset) {
    const metaData = dataset.meta_data.columns[columnId];
    if (metaData) {
      return metaData.name;
    } else {
      return "Unknown";
    }
  }

  public expandRow(index) {
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  public getModelById(id: number) {
    const modelo = this.classificationModels.find((m) => m.id === id);
    if (modelo) {
      return modelo;
    } else {
      return { name: "Unknown" };
    }
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.classificationModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  get classificationModels() {
    return readModels(this.$store);
  }

  get classificationModel() {
    return readModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get models() {
    return readGenerativeModels(this.$store);
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }

  get systemPrompt() {
    if (this.model) {
      if ("system_prompt" in this.model.prompts) {
        return this.model.prompts.system_prompt;
      } else {
        return "";
      }
    }
  }

  get userPrompt() {
    if (this.model) {
      if ("user_prompt" in this.model.prompts) {
        return this.model.prompts.user_prompt;
      } else {
        return "";
      }
    }
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get dataset() {
    return readDataset(this.$store)(+this.$router.currentRoute.params.id);
  }

  public createCombined(datasetId) {
    const datasetFilters = this.model?.filters.filters.filter((f) => f.dataset_id === datasetId);

    if (datasetFilters.length > 0) {
      return datasetFilters[0].filters.map((item) => ({
        ...item,
        combined: item.column_id ? item.column_id : item.inherited_from,
      }));
    } else {
      return [];
    }
  }

  get safeRatio() {
    if (this.model !== undefined) {
      let percentage = Math.round((this.model.total_labeled / this.model.item_count) * 100);
      if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    } else {
      return 0;
    }
  }

  public async delay(ms: number) {
    await new Promise<void>((f) => setTimeout(f, ms));
  }

  public async shareModel() {
    this.inferenceError = null;
    this.loading = true;
    await dispatchShareGenerativeModel(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    })
      .then((res) => {
        this.loading = false;
        this.$router.push(`/main/${this.$router.currentRoute.params.workspaceid}/settings/shared`);
      })
      .catch((err) => {
        this.loading = false;
        this.inferenceError = err.response;
      });
  }

  get statusCheck() {
    // returns true if status indicates training/uploading etc
    return this.model?.status !== "ready" && this.model?.status !== "deployed";
  }

  public dateParse(ts) {
    dayjs.extend(tz);
    dayjs.extend(utc);
    const timeZone = dayjs.tz.guess();
    return dayjs.utc(ts).tz(timeZone).format("MMMM D, YYYY HH:mm");
  }

  public formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public disconnectChoice(datasetId) {
    this.dialogDisconnect = true;
    this.chosenDatasetId = datasetId;
  }

  public async disconnectDataset() {
    this.loading = true;
    await dispatchGetGenerativeModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await api
      .disconnectDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.chosenDatasetId,
      )
      .then((r) => {
        this.e6 = 0;
        this.loading = false;
        this.uploadError = null;
        this.dialogDisconnect = false;
        this.chosenDatasetId = -1;
      })
      .catch((uploadError) => {
        console.log("UploadError", uploadError.response);
        this.loading = false;
        this.uploadError = uploadError.response;
        this.dialogDisconnect = false;
        this.chosenDatasetId = 1;
      });
    await dispatchGetGenerativeModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await this.getAvailableDatasets();
    await this.getConnectedDatasets();
  }

  public async getAvailableDatasets() {
    // await api
    //   .getAvailableDatasets(
    //     this.token,
    //     parseInt(this.$router.currentRoute.params.workspaceid, 10),
    //     parseInt(this.$router.currentRoute.params.id, 10),
    //   )
    //   .then((r) => {
    //     this.availableDatasets = r.data;
    //   })
    //   .catch((error) => {
    //     console.log("UploadError", error.response);
    //   });
  }

  get availablePrivateDatasets() {
    const unsortedAvailableDatasets = this.availableDatasets.filter(
      (dataset) => dataset.public !== true,
    );
    return unsortedAvailableDatasets.slice().sort((a, b) => b.id - a.id);
  }

  public nextStep() {
    this.e6++;
  }

  public promptsAreOK() {
    if (
      this.model?.prompts &&
      this.model?.prompts.user_prompt &&
      this.model?.prompts.user_prompt !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  public async getConnectedDatasets() {
    await dispatchGetGenerativeConnectedDatasets(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    });
    this.keyUpdate = this.keyUpdate + 1;
  }

  public async testModelInference() {
    this.inferenceError = null;
    this.loading = true;
    this.inferenceTestResponse = "";

    api
      .testGenerativeModelInference(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        parseInt(this.$router.currentRoute.params.id, 10),
        this.text,
        this.systemPrompt,
      )
      .then((reader) => {
        let decoder = new TextDecoder();

        const processText = (result: ReadableStreamReadResult<Uint8Array>) => {
          if (result.done) {
            console.log("Stream complete");

            return;
          }

          this.inferenceTestResponse += decoder.decode(result.value); // append response string chunk

          // Read the next chunk
          reader
            .read()
            .then(processText)
            .catch((error) => {
              console.log("error when getting conversation summary", error);
              this.inferenceError = error;
            });
        };

        // Start reading the stream
        reader
          .read()
          .then(processText)
          .catch((error) => {
            console.log("error when getting conversation summary", error);
            this.inferenceError = error;
          });
        this.loading = false;
      })
      .catch((error) => {
        console.log("error when getting conversation summary", error);
        this.inferenceError = error;
        this.loading = false;
      });
  }

  // get firstLabelContainer() {
  //   return readFirstLabelContainer(this.$store)(+this.$router.currentRoute.params.id);
  // }

  public async mounted() {
    await this.fetchModels();
    this.intervalId = window.setInterval(() => {
      this.fetchModels();
    }, 30000);

    this.getConnectedDatasets();
    await this.getAvailableDatasets();
  }

  get token() {
    return readToken(this.$store);
  }

  get connectedDatasets() {
    const connectedDatasetsUnsorted = readConnectedDatasets(this.$store);
    return connectedDatasetsUnsorted.slice().sort((a, b) => b.id - a.id);
  }

  get model() {
    return readGenerativeModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  public beforeRouteLeave(to: any, from: any, next: any) {
    if (this.intervalId !== null) {
      window.clearInterval(this.intervalId);
    }
    next();
  }

  public async fetchModels() {
    await dispatchGetGenerativeModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    this.getConnectedDatasets();
  }

  @Watch("$route", { immediate: true, deep: true })
  public async onRouteChange(to, from) {
    console.log("inhere");
    if (to && from && to.params.id !== from.params.id) {
      await dispatchGetGenerativeModels(this.$store, {
        id: +to.params.workspaceid,
      });
      // Fetch the specific model
      await readGenerativeModel(this.$store)(+to.params.id);
    }
    this.getConnectedDatasets();
    this.getAvailableDatasets();
  }
}
