
import { api } from "@/api";
import DatasetCard from "@/components/DatasetCard.vue";
import FileDrop from "@/components/FileDrop.vue";
import ModelCard from "@/components/ModelCard.vue";
import {
  IColumnInformation,
  IGenerativeModelResponse,
  IGeneratedLabel,
  IModelCreate,
  IModelFilterLabel,
  IModelFilterString,
  IModelFilterTimestamp,
} from "@/interfaces";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import { dispatchGetDatasets } from "@/store/dataset/actions";
import { readDatasets } from "@/store/dataset/getters";
import { readToken } from "@/store/main/getters";
import { dispatchGetModels } from "@/store/model/actions";
import { readModels } from "@/store/model/getters";
import { Component, Vue } from "vue-property-decorator";
import { readLabelsByName } from "@/store/model/getters";
import { readHasAdminAccess } from "@/store/main/getters";

@Component({
  components: {
    FileDrop,
    DatasetCard,
    ModelCard,
    LottieAnimation,
  },
})
export default class ModelUpload extends Vue {
  public dialog: boolean = true;
  public dialogRemove: boolean = false;
  public loading: boolean = false;
  public loadingButton: boolean = false; // loading => disabled buttons
  public name: any = "";
  public description: any = "";
  public activeExampleTab: number[] = [0, 0];
  public progressValue: number = 0;
  public processedExamples: number = 0;
  public numRequests: number;

  public batchSize: number = 25;
  public totalBatches: number = 5;
  public zeroShot: boolean = false;
  public fullConversations: boolean = false;
  public totalExamples: number = 2500;
  public selectedLabels: string[] = [];

  public chosenDatasetId: number;
  public file: any = null;
  public showGuide: boolean = false;
  public availableDatasets: any[] = [];
  public loadingColor: boolean = false;
  public labels: any = [
    { color: null, name: "", description: "", examples: [""] },
    { color: null, name: "", description: "", examples: [""] },
  ];
  public threshold: number = 50;
  public numItems: number = 10;
  public generatedLabels: any = [];

  public recommendationLabels: number = -1;

  public columns: any[] = [];
  public rows: any[] = [];
  public rowsToShow: any[] = [];

  public toggleMulti = false;
  public upload = false;
  public e6: number = 1;
  public BYOL: boolean = false;
  public valSet: number = 10;
  public ownData: boolean = false;

  public connectDataset: boolean = false;
  public modelConnect: number = -1;

  public uploadError: any = null;
  public loadingProgress: number | null = null;

  public dialogFilter: boolean = false;
  public d6: number = 0;
  public recommendationFilter: number = -1;
  public filterSelected: number = -1;
  public filterError: any = null;
  public filterType: string = "must";
  public columnInformation: IColumnInformation = {} as IColumnInformation;
  public pickedValues: any[] = [];
  public showAll: boolean = false;
  public totalItems: number = 0;
  public modelFilters: any = [];
  public expandedRow: number | null = null;

  public previewDialog: boolean = false;
  public responses: any[] = [];
  public datasetItems: any[] = [];
  public loadingResponses: boolean = false;
  public startDate: string = "";
  public endDate: string = "";
  public menuStart: boolean = false;
  public menuEnd: boolean = false;
  public reader: any = null;

  public pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };

  public connectedModels: any[] = [];
  public connectedGenerativeModels: any[] = [];
  public tempModelFilters: any = [];
  public expandedRowModel: number | null = null;
  public loadingGenerative: boolean = false;
  public labelPreview: any = [];
  public taskDescription: string = "";
  public previewError: any = null;
  public pickedGenerativeModelName: string = "";
  public concurrentRequests: number = 50;
  public predictLabelsError: any = null;
  public generationProgress: number = 0;
  public totalGenerated: number = 0;

  public allLabels: any[] = [];

  public colors: string[] = [
    "purple",
    "pink",
    "deep-purple",
    "red",
    "indigo",
    "blue",
    "light-blue",
    "cyan",
    "teal",
    "green",
    "light-green",
    "lime",
    "yellow",
    "amber",
    "orange",
    "deep-orange",
    "brown",
    "blue-grey",
    "grey",
  ];

  get notSelectedColors() {
    // filter and only show the colors that have not been selected from coloroptions
    return this.colorOptions.filter(
      (color) => !this.labels.some((label) => label.color === color.value),
    );
  }
  get chosenDataset() {
    return this.datasets.filter((dataset) => dataset.id === this.chosenDatasetId)[0];
  }

  get labelsNotOk() {
    return (
      this.labels.length < 2 ||
      !this.labels.every(
        (label) =>
          label.name !== "" &&
          label.description !== "" &&
          label.color !== null &&
          this.labelColorOk,
      ) ||
      this.taskDescription === ""
    );
  }

  get labelsNotOkReason() {
    const errors = new Set<string>();

    if (this.labels.length < 2) {
      errors.add("At least two labels are required");
    }

    if (this.taskDescription === "") {
      errors.add("You have to provide a task description");
    }

    for (const label of this.labels) {
      if (label.name === "") {
        errors.add("All labels must have a name");
      }
      if (label.description === "") {
        errors.add("All labels must have a description");
      }
      if (label.color === null) {
        errors.add("All labels must have a color");
      }
    }

    if (!this.labelColorOk) {
      errors.add("All labels must have a unique color");
    }

    return Array.from(errors);
  }

  get labelColorOk() {
    // check if there are no duplicates in the color property
    const colors = this.labels.map((label) => label.color);
    return new Set(colors).size === colors.length;
  }

  get taskDescriptionError() {
    if (this.taskDescription === "" || this.taskDescription.length < 10) {
      return ["A description is required and must be at least 10 characters long"];
    }
  }

  get conversationColumn() {
    if (
      this.chosenDataset &&
      this.chosenDataset.meta_data &&
      this.chosenDataset.meta_data.columns
    ) {
      // Convert the object's values to an array if it's not already an array
      const columnsArray = Array.isArray(this.chosenDataset.meta_data.columns)
        ? this.chosenDataset.meta_data.columns
        : Object.values(this.chosenDataset.meta_data.columns);

      // Now use .find on the array
      const conversationColumn = columnsArray.find(
        (column) => column.special_column === "conversation",
      );
      return conversationColumn.name;
    }
    return "";
  }

  public headersFilter = [
    { text: "Column", value: "combined" },
    { text: "Filter type", value: "filter_type" },
    { text: "Values", value: "filter_values" },
  ];

  get labelPreviewNew() {
    return this.labelPreview.filter((label) => this.labelsByName[label.name] === undefined);
  }

  get labelPreviewExisting() {
    return this.labelPreview.filter((label) => this.labelsByName[label.name] !== undefined);
  }

  get labelsByName() {
    return readLabelsByName(this.$store)(+this.modelConnect);
  }

  get allIncludedLabelsCount() {
    return this.labelPreview.filter((label) => label.include).length;
  }

  public changeIncludeByName(name: string) {
    // Loop through labelPreview
    for (let label of this.labelPreview) {
      // Check if the current label's name matches the provided name
      if (label.name === name) {
        // Toggle the include property
        label.include = !label.include;
        break; // Stop the loop if the label is found
      }
    }
  }

  public labelColorMapper(label: string) {
    // find the color for the label name from the labels array
    return this.labels.find((l) => l.name === label)?.color;
  }

  public sendToConversation(conversationId) {
    // open a new window routing to the conversation view
    const datasetId = this.chosenDatasetId;
    window.open(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/browse?conversation_id=${conversationId}`,
      "_blank",
    );
  }

  get generativeModelsSorted() {
    return this.connectedGenerativeModels.sort((a, b) => {
      if (
        a.status === "deployed" &&
        a.currently_generated > 0 &&
        (b.status !== "deployed" || b.currently_generated <= 0)
      )
        return -1;
      if (
        b.status === "deployed" &&
        b.currently_generated > 0 &&
        (a.status !== "deployed" || a.currently_generated <= 0)
      )
        return 1;
      return 0;
    });
  }

  public isLabelAlreadyAdded(index: number): boolean {
    if (index < 0 || index >= this.generatedLabels.length) {
      return false;
    }

    const generatedLabel = this.generatedLabels[index];
    return this.labels.some(
      (label) =>
        label.name === generatedLabel.name &&
        label.description === generatedLabel.description &&
        label.color === generatedLabel.color,
    );
  }

  public generateLabels() {
    if (this.taskDescription.length > 10) {
      this.generationProgress = 0;
      this.predictLabels();
    }
  }

  public removeLabel(index: number) {
    this.labels.splice(index, 1);
  }

  public removeExample(index: number, exampleIndex: number) {
    this.labels[index].examples.splice(exampleIndex, 1);
  }

  public addNewExample(index: number) {
    // index is the label index
    this.labels[index].examples.push("");
  }

  public acceptLabel(index: number) {
    if (index >= 0 && index < this.generatedLabels.length) {
      const generatedLabel = this.generatedLabels[index];

      // Check if all fields of the current label are empty
      const isLabelEmpty = this.labels.some(
        (label) => !label.name && !label.description && !label.color && !label.example,
      );

      if (isLabelEmpty) {
        // Replace the empty label with the generated one
        const emptyIndex = this.labels.findIndex(
          (label) => !label.name && !label.description && !label.color && !label.example,
        );
        if (emptyIndex !== -1) {
          this.$set(this.labels, emptyIndex, { ...generatedLabel });
          this.activeExampleTab[emptyIndex] = 0;
        }
      } else {
        // Push the generated label to the labels array
        this.labels.push({ ...generatedLabel });
        this.activeExampleTab.push(0);
      }
    }
  }

  public async predictLabels() {
    this.loadingGenerative = true;
    this.predictLabelsError = null;
    this.generationProgress = 0;
    this.totalGenerated = 0;

    try {
      const reader = await api.generateLabels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        -1,
        this.chosenDatasetId,
        this.modelFilters,
        this.taskDescription,
      );

      this.reader = reader;
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        // Decode the received chunk
        const chunk = decoder.decode(value);

        // Split the chunk into lines and process each SSE event
        const lines = chunk.split("\n");
        let eventData = "";

        for (const line of lines) {
          if (line.startsWith("data: ")) {
            // Extract the data part
            eventData = line.slice(6);

            try {
              const parsedData = JSON.parse(eventData);

              switch (parsedData.event) {
                case "progress":
                  this.generationProgress = parsedData.data.progress;
                  this.totalGenerated = parsedData.data.total_generated;
                  break;

                case "complete":
                  this.generatedLabels = JSON.parse(parsedData.data).labels;
                  this.generatedLabels.forEach((label) => {
                    label.examples = [""];
                  });
                  this.loadingGenerative = false;
                  return;

                case "error":
                  console.error("Error from server:", parsedData.data);
                  this.loadingGenerative = false;
                  this.predictLabelsError = parsedData;
                  return;
              }
            } catch (e) {
              // Ignore parsing errors for non-data lines
              if (line.trim() && !line.includes(": ping")) {
                console.error("Error parsing server message:", e, "Line:", line);
                this.predictLabelsError = {data: {detail: "Parseing error"}};
                this.loadingGenerative = false;
              }
            }
          }
        }
      }
    } catch (error) {
      this.loadingGenerative = false;
      console.error("Error in predictLabels:", error);
      console.error("Error in predictLabels:", error);
      this.predictLabelsError = (error as { response: unknown }).response;
    } finally {
      this.loadingGenerative = false;
      if (this.reader) {
        this.reader.releaseLock();
        this.reader = null;
      }
    }
  }

  beforeDestroy() {
    // Clean up the reader if component is destroyed
    if (this.reader) {
      this.reader.releaseLock();
      this.reader = null;
    }
  }

  get colorOptions() {
    return [
      { name: "No color", value: null },
      ...this.colors.map((color) => ({ name: color, value: color })),
    ];
  }

  public changeInclude(index: number) {
    this.labelPreview[index].include = !this.labelPreview[index].include;
  }

  public getIncludeIcon(include: boolean) {
    if (include) {
      return "check";
    } else {
      return "close";
    }
  }

  public inherit(filter: any) {
    this.tempModelFilters.push(filter);
  }

  public getLabelsById(ids: number[], modelId: number) {
    const modelo = this.connectedModels.find((m) => m.id === modelId);
    if (modelo) {
      return modelo.label_containers[0].labels.filter((l) => ids.includes(l.id));
    } else {
      return [];
    }
  }

  public getModelById(id: number) {
    return this.connectedModels.find((m) => m.id === id);
  }

  public createTempModelFilters() {
    this.tempModelFilters = this.modelFilters.slice();

    const filter: IModelFilterLabel = {
      filter_type: "label",
      label_id: this.pickedModelAndLabels.labels,
      inherited_from: this.pickedModelAndLabels.model,
      label_container: this.pickedModelAndLabels.label_container,
    };

    this.tempModelFilters.push(filter);
  }

  public doesFilterAlreadyExist(column, modelId) {
    return this.tempModelFilters.some(
      (filter: any) => filter.column_id === column && filter.inherited_from === modelId,
    );
  }

  get getModelFiltersOfPickedModel() {
    const modelo = this.connectedModels.find((m) => m.id === this.pickedModelAndLabels.model);

    // If a matching model is found, return its filters
    if (modelo) {
      const datasetModelFilter = modelo.filters.filters.find(
        (m) => m.dataset_id === this.chosenDatasetId,
      );
      if (datasetModelFilter) {
        return datasetModelFilter.filters;
      }
      return [];
    }

    // If no matching model is found, return []
    return [];
  }

  public async newColor(index, data) {
    this.labels[index].color = data.value;
  }

  get getModelAndLabels() {
    if (this.pickedModelAndLabels.model === -1) {
      return {
        modelName: "unknown",
        labels: [],
      };
    }

    const pickedModel = this.connectedModels.find(
      (model) => model.id === this.pickedModelAndLabels.model,
    );

    if (!pickedModel) {
      throw new Error(`Model with id ${this.pickedModelAndLabels.model} not found`);
    }

    const labels = pickedModel.label_containers
      .flatMap((container) => container.labels)
      .filter((label) => this.pickedModelAndLabels.labels.includes(label.id));

    return {
      modelName: pickedModel.name,
      labels,
    };
  }

  public pickModelAndLabel(labelId: number, modelId: number, labelContainerId: number) {
    this.pickedModelAndLabels.model = modelId;
    this.pickedModelAndLabels.label_container = labelContainerId;
  }

  public async getDatasetConnectedModels() {
    await api
      .getDatasetConnectedModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
      )
      .then((r) => {
        this.connectedModels = r.data;
      })
      .catch((error) => {});
  }

  public async getDatasetConnectedGenerativeModels() {
    await api
      .getDatasetConnectedGenerativeModels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
      )
      .then((r) => {
        this.connectedGenerativeModels = r.data;
      })
      .catch((error) => {});
  }

  public createCombined(data) {
    return data.map((item) => ({
      ...item,
      combined: item.column_id ? item.column_id : item.inherited_from,
    }));
  }

  public labelNameError(index) {
    if (this.labels.length < 1) {
      return ["At least two labels are required"];
    }
    if (this.labels[index].name === "" || this.labels[index].name.length < 1) {
      return ["A label name is required and must be at least 1 characters long"];
    }
  }

  public labelDescriptionError(index) {
    if (this.labels[index].description === "" || this.labels[index].description.length < 2) {
      return ["A label description is required and must be at least 2 characters long"];
    }
  }

  public addNewLabel() {
    this.labels.push({ color: null, name: "", description: "", examples: [""] });
  }
  get maxAndMinDate() {
    let minDate: any = "";
    let maxDate: any = "";
    //
    if (this.columnInformation.column_values.length > 0) {
      minDate = new Date(
        Math.min(...this.columnInformation.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
      maxDate = new Date(
        Math.max(...this.columnInformation.column_values.map((date) => new Date(date).getTime())),
      )
        .toISOString()
        .slice(0, 10);
    }
    return { minDate, maxDate };
  }

  public getFilterTypeTextify(filterType: string) {
    if (filterType === "must") {
      return "Must contain";
    } else if (filterType === "must_not") {
      return "Must not contain";
    } else if (filterType === "timestamp") {
      return "Date filter";
    } else if (filterType === "label") {
      return "Label filter";
    } else {
      return "Unknown";
    }
  }

  public checkAllowedDateStart(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() + 1);

    if (
      this.columnInformation.column_values.includes(date) &&
      currentDate <= new Date(this.maxAndMinDate.maxDate)
    ) {
      if (this.endDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const endDateObj: Date = new Date(this.endDate);
        const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() + 1);
        if (endDateObj >= currentDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public checkAllowedDateEnd(date) {
    const currentDate: Date = new Date(date);
    currentDate.setMonth(currentDate.getMonth() - 1);

    if (
      this.columnInformation.column_values.includes(date) &&
      currentDate >= new Date(this.maxAndMinDate.minDate)
    ) {
      if (this.startDate) {
        // need at least 2 months. (+1 and > = 2 months)
        const startDateObj: Date = new Date(this.startDate);
        const currentDate: Date = new Date(date);
        currentDate.setMonth(currentDate.getMonth() - 1);
        if (currentDate >= startDateObj) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  get isAdmin() {
    return readHasAdminAccess;
  }

  public isValueInPickedValues(value: any) {
    return this.pickedValues.some((val) => val === value);
  }

  public deleteFilter(index: number) {
    this.modelFilters.splice(index, 1);
  }

  public deleteTempFilter(index: number) {
    this.tempModelFilters.splice(index, 1);
  }

  public toggle() {
    const allPresentInPickedValues = this.columnInformation.column_values.every((obj) =>
      this.pickedValues.includes(obj.value),
    );
    if (!allPresentInPickedValues) {
      this.columnInformation.column_values.forEach((obj) => {
        if (!this.pickedValues.includes(obj.value)) {
          this.pickedValues.push(obj.value);
        }
      });
    } else {
      this.pickedValues = [];
    }
  }

  public expandRow(index) {
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  public closeFilterDialog() {
    this.dialogFilter = false;
    this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
    this.d6 = 0;
    this.recommendationFilter = -1;
    this.filterSelected = 0;
    this.filterError = null;
    this.filterType = "must";
    this.columnInformation = {} as IColumnInformation;
    this.pickedValues = [];
    this.showAll = false;
    this.endDate = "";
    this.startDate = "";
    this.tempModelFilters = [];
    this.expandedRowModel = null;
  }

  public goBackFilter() {
    if (this.filterSelected === 0) {
      if (this.d6 === 1) {
        this.d6 = 0;
      } else if (this.d6 === 2) {
        this.d6 = 1;
        this.pickedValues = [];
        this.startDate = "";
        this.endDate = "";
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    } else if (this.filterSelected === 1) {
      if (this.d6 === 1) {
        this.d6 = 0;
        this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
      } else if (this.d6 === 2) {
        this.tempModelFilters = [];
        this.expandedRowModel = null;
        this.d6 = 1;
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 2;
        this.showAll = false;
      }
    } else {
      if (this.d6 === 1) {
        this.d6 = 0;
        this.pickedModelAndLabels = { model: -1, labels: [] as number[], label_container: -1 };
      } else if (this.d6 === 3) {
        this.totalItems = 0;
        this.d6 = 1;
        this.showAll = false;
        this.pickedGenerativeModelName = "";
      }
    }
  }

  public nextStepFilter() {
    if (this.filterSelected === 0 || this.filterSelected === 2) {
      if (this.d6 === 0) {
        this.d6 = 1;
      } else if (this.d6 === 1) {
        this.filterColumnValues();
      } else if (this.d6 === 2) {
        this.getFilterCount();
      } else if (this.d6 === 3) {
        this.addModelFilter();
        this.closeFilterDialog();
      }
    } else {
      if (this.d6 === 0) {
        this.d6 = 1;
      } else if (this.d6 === 1) {
        this.d6 = 2;
        this.createTempModelFilters();
      } else if (this.d6 === 2) {
        this.getFilterCount(false);
      } else if (this.d6 === 3) {
        this.modelFilters = this.tempModelFilters.slice();
        this.closeFilterDialog();
      }
    }
  }

  public async pickGenerativeModel(model) {
    // since we add speaker as column, it is fine to hardcode the name value here
    this.columnInformation.store_column_name = "speaker";
    this.columnInformation.dtype = "keyword";
    this.filterType = "must";
    this.pickedValues = [model.name];
    this.pickedGenerativeModelName = model.name;

    const datasetWithMetaData = this.datasets.filter(
      (dataset) => dataset.id === this.chosenDatasetId,
    )[0];

    const speakerColumn = Object.entries(datasetWithMetaData.meta_data.columns).find(
      ([_, column]) => (column as { name: string }).name === "speaker",
    );
    if (speakerColumn) {
      const [index, column] = speakerColumn;
      this.recommendationFilter = parseInt(index, 10);
    }

    await this.getFilterCount(true);
    this.d6 = 3;
  }

  public getFilterColumnName(id) {
    if (this.rows.length > 0) {
      const columnIndex = `column_${id}`;
      const row = this.rows[0];
      if (row.hasOwnProperty(columnIndex)) {
        return row[columnIndex];
      }
    } else {
      return "None";
    }
  }

  public addModelFilter() {
    if (this.filterType !== "timestamp") {
      const filter: IModelFilterString = {
        column_id: this.recommendationFilter,
        store_column_name: this.columnInformation.store_column_name,
        filter_values: this.pickedValues,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
      };
      this.modelFilters.push(filter);
    } else {
      const filter: IModelFilterTimestamp = {
        column_id: this.recommendationFilter,
        store_column_name: this.columnInformation.store_column_name,
        dtype: this.columnInformation.dtype,
        filter_type: this.filterType,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      this.modelFilters.push(filter);
    }
  }

  public async getFilterCount(datasetFilter = true) {
    this.loadingButton = true;
    this.filterError = null;
    let allFilters = this.modelFilters.slice();
    if (datasetFilter === true) {
      if (this.filterType !== "timestamp") {
        const filter: IModelFilterString = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          filter_values: this.pickedValues,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
        };

        allFilters.push(filter);
      } else {
        const filter: IModelFilterTimestamp = {
          store_column_name: this.columnInformation.store_column_name,
          column_id: this.recommendationFilter,
          dtype: this.columnInformation.dtype,
          filter_type: this.filterType,
          start_date: this.startDate,
          end_date: this.endDate,
        };

        allFilters.push(filter);
      }
    } else {
      allFilters = this.tempModelFilters.slice();
    }

    await api
      .getFilterCount(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        allFilters,
      )
      .then((r) => {
        this.loadingButton = false;
        this.totalItems = r.data.total_items;
        this.d6 = 3;
      })
      .catch((error) => {
        this.loadingButton = false;
        this.filterError = error.response;
      });
  }

  public async filterColumnValues() {
    this.loadingButton = true;
    this.filterError = null;
    await api
      .filterColumnValues(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.recommendationFilter,
        this.modelFilters,
      )
      .then((r) => {
        this.columnInformation = r.data;
        this.loadingButton = false;
        this.d6 = 2;
        if (this.columnInformation.dtype === "timestamp") {
          this.filterType = "timestamp";
        } else {
          this.filterType = "must";
        }
      })
      .catch((error) => {
        this.loadingButton = false;
        this.filterError = error.response;
        this.columnInformation = {} as IColumnInformation;
      });
  }

  public async mounted() {
    await dispatchGetModels(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    await dispatchGetDatasets(this.$store, {
      id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
    });
    // store params if we were redirected
    if (this.$route.query.connect === "true") {
      const tmp: any = this.$route.query.model_id;
      this.modelConnect = parseInt(tmp, 10);

      this.connectDataset = true;
      this.e6 = 1;
      this.ownData = true;
      await this.getAvailableDatasets();
    }

    if (this.$route.query.redirected === "true") {
      if (this.$route.query.dataset_id !== null && this.$route.query.trustpilot === "false") {
        this.chooseDataset(this.$route.query.dataset_id);
        this.e6 = 2;
      }
      this.ownData = true;
    }
  }

  public async getAvailableDatasets() {
    await api
      .getAvailableDatasets(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.modelConnect,
      )
      .then((r) => {
        this.availableDatasets = r.data;
      })
      .catch((error) => {});
  }

  get availablePrivateDatasets() {
    const unsortedAvailableDatasets = this.availableDatasets.filter(
      (dataset) => dataset.public !== true,
    );
    return unsortedAvailableDatasets.slice().sort((a, b) => b.id - a.id);
  }

  get datasets() {
    return readDatasets(this.$store);
  }

  get privateDatasets() {
    const pDatasets = this.datasets.filter((dataset) => dataset.public !== true);
    const sortedPDatasets = pDatasets.slice().sort((a, b) => b.id - a.id);
    return sortedPDatasets;
  }

  public async goBack() {
    if (this.e6 === 2) {
      // go back to step 1 (pick dataset)
      this.chosenDatasetId = -1;
      this.columns = [];
      this.rows = [];
      this.rowsToShow = this.rows;
      this.name = "";
      this.recommendationLabels = -1;
      this.toggleMulti = false;
      this.valSet = 10;
      this.e6 = 1;
      this.totalItems = 0;
      this.numItems = 0;
    } else if (this.e6 === 3) {
      this.e6 = 2;
      this.labelPreview = [];
    } else if (this.e6 === 5) {
      this.description = "";
      if (this.recommendationLabels === -1) {
        this.e6 = 3;
      } else {
        this.e6 = 4;
      }
    } else if (this.e6 === 3) {
      this.e6 = 2;
    } else if (this.e6 === 4) {
      this.e6 = 3;
    }
  }

  public async chooseDataset(datasetId) {
    this.loadingButton = true;
    await api
      .getDatasetPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        datasetId,
      )
      .then((r) => {
        this.loadingButton = false;
        this.chosenDatasetId = datasetId;
        this.uploadError = null;
        this.columns = r.data.columns;
        this.rows = r.data.rows;
        // this.headDialog = true;
        this.rowsToShow = this.rows;
        this.nextStep();
        this.prefillName();
        this.getDatasetConnectedModels();
        this.getDatasetConnectedGenerativeModels();
      })
      .catch((error) => {
        this.loadingButton = false;
      });
  }

  public async prefillName() {
    const chosenDataset = this.datasets.filter((dataset) => dataset.id === this.chosenDatasetId);

    try {
      this.name = chosenDataset[0].name + " model";
    } catch {}
  }

  public highlightFilter(key, value) {
    let newColumn = this.recommendationFilter;

    if (Number(key) || Number(key) === 0) {
      if (
        !this.isThisTextColumn(key) &&
        this.modelFilters.filter((filter) => filter.column_id === key).length === 0
      ) {
        newColumn = Number(key);
      }
    }

    if (this.recommendationFilter === newColumn) {
      this.recommendationFilter = -1;
    } else {
      this.recommendationFilter = newColumn;
    }
  }

  public toggleMultiFilter() {
    if (this.recommendationLabels !== -1) {
      const columnName = `column_${this.recommendationLabels}`;

      // Initialize a counter
      let count = 0;

      // Loop through each row in the rows array
      for (const row of this.rows) {
        // Get the value from the column of interest
        const value = row[columnName];

        // Check if the value is a string and meets the criteria
        if (
          typeof value === "string" &&
          (value.includes("|") || (value.startsWith("[") && value.endsWith("]")))
        ) {
          count++;
        }
      }
      if (count > 8) {
        this.toggleMulti = true;
      } else {
        this.toggleMulti = false;
      }
    } else {
      this.toggleMulti = false;
    }
  }

  public highlight(key, value) {
    let newColumn = this.recommendationLabels;
    if (Number(key) || Number(key) === 0) {
      if (!this.isThisTextColumn(key)) {
        newColumn = Number(key);
      }
    }
    if (this.recommendationLabels === newColumn) {
      this.recommendationLabels = -1;
    } else {
      this.recommendationLabels = newColumn;
    }
    this.toggleMultiFilter();
  }

  get previewRows() {
    return this.rows.slice(1);
  }

  get activeRecommendationLabels() {
    if (this.recommendationLabels > -1) {
      return this.columns[this.recommendationLabels].value;
    } else {
      return "None";
    }
  }

  get activeRecommendationFilter() {
    if (this.recommendationFilter > -1) {
      return this.columns[this.recommendationFilter].value;
    } else {
      return "None";
    }
  }

  get tableDataHeader() {
    const rows: any[] = [];
    this.columns.forEach((column, key) => {
      const text = "";
      rows.push({
        text: this.rows[0][column.value] + text,
        value: column.value,
        align: "left",
      });
    });

    return rows;
  }

  get models() {
    return readModels(this.$store);
  }

  get nameExists(): boolean {
    return this.models.filter((model) => model.name === this.name).length > 0;
  }

  get nameErrors(): string[] {
    if (this.nameExists) {
      return ["This name does already exist"];
    }
    if (this.name.length && (this.name.length < 1 || this.name.length > 100)) {
      return ["Please provide a name between 1 and 100 characters long"];
    }
    return [];
  }

  get datasetNameExists(): boolean {
    if (this.datasets?.length) {
      return this.datasets.filter((dataset) => dataset.name === this.name).length > 0;
    } else {
      return false;
    }
  }

  get isClean(): boolean {
    if (this.name.length < 1 || this.name.length > 100) {
      return false;
    }
    if (this.nameExists) {
      return false;
    }

    return true;
  }

  public isThisTextColumn(key: number) {
    const datasetWithMetaData = this.datasets.filter(
      (dataset) => dataset.id === this.chosenDatasetId,
    )[0];
    const keyString = String(key); // Convert the key to a string

    if (datasetWithMetaData.meta_data.columns.hasOwnProperty(keyString)) {
      const column = datasetWithMetaData.meta_data.columns[keyString];

      if (column.hasOwnProperty("special_column") && column.special_column === "text") {
        return true;
      }
    }
    return false;
  }

  get token() {
    return readToken(this.$store);
  }

  public async nextStep() {
    if (this.e6 === 2) {
      dispatchGetDatasets(this.$store, {
        id: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      });
    }

    if (this.e6 === 3 && this.recommendationLabels !== -1) {
      await this.getLabelPreview();
      this.e6 = 4;
      return;
    }
    if (this.e6 === 3 && this.recommendationLabels === -1) {
      this.e6 = 5;
      return;
    }

    if (this.e6 === 3) {
      if (this.labelsNotOk) {
        this.e6 = 4;
      } else {
        this.e6 = 5;
      }
      return;
    } else if (this.e6 !== 3 && this.e6 !== 5) {
      this.e6++;
      return;
    }
  }

  public async getLabelPreview() {
    this.loadingButton = true;
    this.previewError = null;

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    await api
      .labelPreview(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.recommendationLabels,
        this.toggleMulti,
        filter,
      )
      .then((r) => {
        this.loadingButton = false;
        this.previewError = null;
        this.labelPreview = r.data;
      })
      .catch((uploadError) => {
        this.loadingButton = false;
        this.previewError = uploadError.response;
      });
  }

  public async createModel() {
    if (!this.labelsNotOk) {
      await this.createModelFromUploadWithCustomLabels();
      return;
    }

    if (!this.connectDataset) {
      if (this.recommendationLabels !== -1) {
        await this.createModelFromUploadWithLabels();
      } else {
        await this.createModelFromUpload();
      }
    } else {
      if (this.recommendationLabels !== -1) {
        await this.connectDatasetWithLabels();
      } else {
        await this.connectDatasetWithoutLabels();
      }
    }
  }

  public async connectDatasetWithLabels() {
    this.loading = true;

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    let labelManager: any = { actions: [] };
    this.labelPreview.forEach((a) => {
      if (!a.include) {
        labelManager.actions.push({
          action: "exclude",
          label_name: a.name,
        });
      }
    });

    await api
      .connectDatasetByol(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.modelConnect,
        this.chosenDatasetId,
        this.recommendationLabels,
        filter,
        labelManager,
      )
      .then((r) => {
        this.e6 = 1;
        this.loading = false;
        this.uploadError = null;
        this.recommendationLabels = -1;
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.modelConnect}/dashboard/dashboard`,
        );
      })
      .catch((uploadError) => {
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public async connectDatasetWithoutLabels() {
    this.loading = true;

    let filter: any[] | boolean = false;

    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    await api
      .connectDataset(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.modelConnect,
        this.chosenDatasetId,
        filter,
      )
      .then((r) => {
        this.e6 = 1;
        this.loading = false;
        this.uploadError = null;
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/${this.modelConnect}/dashboard/dashboard`,
        );
      })
      .catch((uploadError) => {
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public async createModelFromUpload() {
    this.loading = true;
    const model: IModelCreate = {
      name: this.name,
      description: this.description,
    };

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    await api
      .createModelFromUpload(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        filter,
        model,
      )
      .then((r) => {
        this.recommendationLabels = -1;
        this.loading = false;
        this.uploadError = null;
        this.name = "";
        this.description = "";
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/view`,
        );
      })
      .catch((uploadError) => {
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public async createModelFromUploadWithCustomLabels() {
    this.loading = true;
    const model: IModelCreate = {
      name: this.name,
      description: this.description,
    };

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    await api
      .createModelFromUploadWithCustomLabels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        filter,
        this.labels,
        model,
        this.taskDescription,
        this.threshold / 100, // threshold should be in the scale of (0, 1)
        this.numItems,
        this.fullConversations,
        this.selectedLabels,
        this.recommendationLabels,
      )
      .then((r) => {
        this.recommendationLabels = -1;
        this.loading = false;
        this.uploadError = null;
        this.name = "";
        this.description = "";
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/view`,
        );
      })
      .catch((uploadError) => {
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }

  public async createModelFromUploadWithLabels() {
    this.uploadError = null;
    this.loading = true;
    const model: IModelCreate = {
      name: this.name,
      description: this.description,
    };

    let filter: any[] | boolean = false;
    if (this.modelFilters.length !== 0) {
      filter = this.modelFilters.slice();
    }

    let labelManager: any = { actions: [] };
    this.labelPreview.forEach((a) => {
      // if the label is not included or not selected, we exclude it
      if (!a.include || !this.selectedLabels.includes(a.name)) {
        labelManager.actions.push({
          action: "exclude",
          label_name: a.name,
        });
      }
    });

    await api
      .createModelFromUploadWithLabels(
        this.token,
        parseInt(this.$router.currentRoute.params.workspaceid, 10),
        this.chosenDatasetId,
        this.recommendationLabels,
        this.toggleMulti,
        this.valSet,
        filter,
        model,
        labelManager,
      )
      .then((r) => {
        this.recommendationLabels = -1;
        this.loading = false;
        this.uploadError = null;
        this.name = "";
        this.description = "";
        this.$router.push(
          `/main/${this.$router.currentRoute.params.workspaceid}/classification/view`,
        );
      })
      .catch((uploadError) => {
        this.loading = false;
        this.uploadError = uploadError.response;
      });
  }
}
