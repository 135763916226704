
import { Component, Vue } from "vue-property-decorator";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { api } from "@/api";
import { appName, supersetUrl } from "@/env";
import axios from "axios";
import { table } from "console";
import { readWorkspace, readToken } from "@/store/main/getters";

@Component
export default class Editor extends Vue {
  public supersetUrl2: string = supersetUrl;
  get token() {
    return readToken(this.$store);
  }

  public async mounted() {
    console.log("supersetURL is", supersetUrl)
    console.log("supersetURL2 is before", this.supersetUrl2)
    const supersetUrl2: string = supersetUrl;
    console.log("supersetURL2 is after", this.supersetUrl2)
  }
}
